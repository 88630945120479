import * as React from "react";
import { List, Datagrid, TextField, DateField, NumberField } from "react-admin";

const PaymentsDueList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="external_id" />
      <TextField source="username_cache" />
      <TextField source="collections_status_filter" />
      <TextField source="payment_days_overdue" />
      <DateField source="first_payment_date" />
      <NumberField source="regular_payment_day" />
      <NumberField source="regular_payment_amount" />
    </Datagrid>
  </List>
);
export default PaymentsDueList;
