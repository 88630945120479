import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  DateInput,
} from "react-admin";
import useStyles from "./Styles";

type Props = {};

const ProfileEdit = (props: Props) => {
  const classes = useStyles();
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="email" />
        <TextInput source="title" formClassName={classes.startFormGroup} />
        <TextInput source="first_name" formClassName={classes.nextFormGroup} />
        <TextInput source="family_name" formClassName={classes.nextFormGroup} />
        <div />
        <NumberInput
          source="customer_income"
          formClassName={classes.startFormGroup}
        />
        <NumberInput
          source="other_income"
          formClassName={classes.nextFormGroup}
        />
        <NumberInput
          source="net_income"
          formClassName={classes.nextFormGroup}
        />
        <NumberInput
          source="net_other_income"
          formClassName={classes.nextFormGroup}
        />
        <div />
        <TextInput
          source="employment_status"
          formClassName={classes.startFormGroup}
        />
        <TextInput source="occupation" formClassName={classes.nextFormGroup} />
        <TextInput
          source="employer_name"
          formClassName={classes.nextFormGroup}
        />
        <NumberInput
          source="employment_duration_years"
          formClassName={classes.nextFormGroup}
        />
        <div />
        <BooleanInput
          source="allow_soft_search"
          formClassName={classes.startFormGroup}
        />
        <DateInput
          source="allow_soft_search_timestamp"
          formClassName={classes.nextFormGroup}
        />
        <DateInput source="birthday" />
        <TextInput source="phone" />
        <DateInput source="created_at" />
        <DateInput source="updated_at" />
        <NumberInput source="user" />
        <NumberInput source="affordability" />
      </SimpleForm>
    </Edit>
  );
};

export default ProfileEdit;
