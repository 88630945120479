import * as React from "react";
import { Fragment, useEffect } from "react";
import { Button as RAButton, useRefresh, useMutation } from "react-admin";

interface Option {
  id: string;
  name: string;
}

const CreateButton = ({ application }: any) => {
  const refresh = useRefresh();
  const [mutate, { loading }] = useMutation();
  const update = (event: any) => {
    mutate({
      type: "create",
      resource: "loans/campaign/ntu/create",
      payload: {},
    });
  };
  useEffect(() => {
    refresh();
  }, [refresh, loading]);
  const handleClick = async () => {
    update({});
  };
  return (
    <Fragment>
      {loading ? (
        <RAButton label="Creating..." />
      ) : (
        <RAButton label="Create new campaign entries" onClick={handleClick} />
      )}
    </Fragment>
  );
};

export default CreateButton;
