import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
  NumberField,
} from "react-admin";

const MandateShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="accountName" />
      <TextField source="accountNumber" />
      <TextField source="sortCode" />
      <TextField source="externalReference" />
      <TextField source="reference" />
      <TextField source="modulrId" />
      <TextField source="status" />
      <TextField source="userId" />
      <TextField source="modulr_status" />
      <TextField source="modulr_status_updated" />
      <ArrayField source="collectionschedule_set">
        <Datagrid>
          <DateField source="firstPaymentDate" />
          <TextField source="externalReference" />
          <TextField source="status" />
          <TextField source="modulr_status" />
          <DateField source="modulr_status_updated" />
          <NumberField source="firstPaymentAmount" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default MandateShow;
