const AccountTypeMappings: { [title: string]: string } = {
  AD: "Asset Discounting",
  AF: "Agricultural Finance",
  AM: "Airtime Agreement - monthly contract",
  AU: "Airtime Agreement upfront",
  BA: "Balloon Repayment Loan",
  BC: "Book/Music/Video Club",
  BD: "Budget Account",
  BH: "Balloon HP",
  BI: "Buildings Insurance",
  BK: "Bank",
  BL: "Unsecured Business Loan",
  BM: "Residential Buy to Let",
  BN: "Buy Now Pay Later",
  BO: "Broadband",
  BR: "Brewery Loan",
  BU: "Business Line",
  BX: "Builders Merchant Credit",
  CA: "Current Account",
  CB: "Cable",
  CC: "Credit Card",
  CD: "Crown Debt",
  CH: "Charge Card",
  CI: "Contents Insurance",
  CM: "Commercial Mortgage",
  CO: "Company Credit Card (individual responsible for debt)",
  CP: "Personal Contract Purchase",
  CR: "Consumer Goods Rental",
  CS: "Unsecured Car Loan",
  CT: "Council Tax",
  CX: "Credit Sale",
  CY: "Conditional Sale",
  CZ: "Combined Credit Account",
  DA: "Consolidated Debt",
  DC: "Debit Card",
  DF: "Bank Default",
  DH: "Deferred HP",
  DM: "Shared Ownership Mortgage (Housing Association)",
  DP: "Deferred Payment",
  DU: "Dual Fuel",
  EC: "E-Cash",
  ED: "Education",
  EE: "Electricity - even payment",
  EL: "Employer Loan",
  EW: "Water - even payment",
  FC: "Fuel Card (individual responsible for debt)",
  FD: "Fixed Term Deferred Payment Loan",
  FL: "Finance Lease",
  FM: "Flexible Mortgage",
  FO: "Residential First Mortgage with Investment Offset",
  FS: "Further Secured Loan",
  FT: "Factoring",
  GA: "Gambling",
  GE: "Gas - even payment",
  GI: "General Insurance",
  GL: "Guarantee Liability",
  HA: "Home Shopping Agency",
  HC: "Home Credit",
  HD: "Home Shopping Direct",
  HI: "Household Insurance",
  HP: "Hire Purchase",
  HS: "Home Shopping",
  HX: "Home Shopping Cash",
  IC: "Internet Credit Line",
  IL: "Advance Against Income",
  IM: "Residential Second Mortgage with Investment Offset",
  IN: "Insurance",
  IS: "Internet Shopping",
  LN: "Loan (unspecified type)",
  LP: "Life Policy Loan",
  LR: "Local Rates - even payment scheme",
  LS: "Lease",
  LT: "Landline Telephone - even payment",
  MA: "Mail Order Agency",
  MC: "Multifunctional Card",
  MG: "Mortgage (unspecified type)",
  MI: "Motor Insurance",
  ML: "Motor Finance Loan general",
  MM: "Semi-Commercial First Mortgage",
  MO: "Mail Order",
  MP: "Mortgage Protection",
  MT: "Mortgage and Unsecured Loan",
  MU: "Multi Communications",
  NM: "Semi-Commercial Second Mortgage",
  OA: "Option Account",
  OD: "Overdraft",
  OI: "Oil",
  OL: "Operating Lease",
  OM: "Investment Offset Mortgage",
  OR: "Other Rental",
  PI: "Personal Health Insurance",
  PL: "Petroleum Loan",
  PP: "Payment Protection",
  PR: "Property Rental",
  PT: "Card Protection",
  QA: "Airtime Agreement - quarterly payment",
  QE: "Electricity - quarterly payment",
  QG: "Gas - quarterly payment",
  QT: "Landline Telephone - quarterly payment",
  QU: "Utility - quarterly payment",
  QW: "Water - quarterly payment",
  RC: "Revolving Credit",
  RG: "Repayment Grant",
  RM: "Residential Mortgage",
  RQ: "Returned Cheque",
  RS: "Retail Store Card",
  RT: "Rental Agreement (General)",
  SA: "Stock Broking Account",
  SB: "Secured Business Loan",
  SC: "Secured Car Loan",
  SE: "Secured Loan",
  SL: "Student Loan",
  SM: "Residential Second Mortgage",
  SO: "Offset Loan",
  SI: "Satellite",
  SS: "Stationery Supplier",
  ST: "Store Card",
  SX: "Offset Current Account (if offset against mortgage then use FO or IM)",
  TL: "Term Loan",
  TM: "Telecommunications Supplier",
  TR: "Trade Credit",
  TV: "TV Shopping",
  UE: "Utility - even payment",
  UL: "Unsecured Loan",
  UT: "Utility",
  VS: "Variable Subscription",
  WI: "Weekly Installment Plan",
  XM: "Residential First Time Buyer",
  ZC: "Standby Credit",
  ZH: "0% HP",
  ZL: "0% Finance Loan",
  ZM: "100+% LTV Mortgage",
};

export default AccountTypeMappings;
