import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  Toolbar,
  SaveButton,
} from "react-admin";

type Props = {};

const EditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const accounttransactionEdit = (props: Props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="id" />
      <TextInput source="merchant_display" />
      <DateInput source="created_at" />
      <DateInput source="updated_at" />
      <TextInput source="amount" />
      <TextInput source="currency" />
      <DateInput source="timestamp" />
      <TextInput source="transaction_type" />
      <TextInput source="transaction_category" />
      <TextInput source="description" />
      <TextInput source="meta" />
      <TextInput source="transaction_classification" />
      <TextInput source="merchant_name" />
      <TextInput source="running_balance" />
      <TextInput source="running_balance_currency" />
      <TextInput source="estimated_balance" />
      <TextInput source="balance" />
      <TextInput source="underwriting_category" />
      <TextInput source="underwriting_subcategory" />
      <TextInput source="spend_cat_1" />
      <TextInput source="spend_cat_2" />
      <TextInput source="spend_class_1" />
      <TextInput source="spend_merchant" />
    </SimpleForm>
  </Edit>
);

export default accounttransactionEdit;
