import * as React from "react";
import {
  Fragment,
  useEffect,
} from "react";
import {Button as RAButton, useRefresh, useMutation, useGetIdentity} from "react-admin";

interface Option {
  id: string;
  name: string;
}

const AddToBlockListButton = ({ application }: any) => {
  const currentURL = window.location.href;
  const refresh = useRefresh();
  const [mutate, { loading }] = useMutation();
  const user = localStorage.getItem("user")
  const create = (event: any) => {
    mutate({
      type: "create",
      resource: "loans/blocklist",
      payload: {
        id: application.user_id,
        data: {
          admin_id: user,
          admin_name: user,
          url: currentURL
        },
      },
    });
  };
  useEffect(() => {
    refresh();
  }, [refresh, loading]);
  const handleClick = async () => {
    create({});
  };
  return (
    <Fragment>
      {loading ? <RAButton label="Creating..." /> : <RAButton label="Add to Blocklist" onClick={handleClick} />}
    </Fragment>
  );
};

export default AddToBlockListButton;
