import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  BooleanField,
  DateField,
  EmailField,
} from "react-admin";
import useStyles from "./Styles";

type Props = {};

const ProfileShow = (props: Props) => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <EmailField source="profile.email" />
        <TextField source="profile.title" className={classes.startFormGroup} />
        <TextField
          source="profile.first_name"
          className={classes.nextFormGroup}
        />
        <TextField
          source="profile.family_name"
          className={classes.nextFormGroup}
        />
        <DateField source="profile.birthday" />
        <TextField source="profile.phone" />
        <NumberField
          source="profile.customer_income"
          className={classes.startFormGroup}
        />
        <NumberField
          source="profile.other_income"
          className={classes.nextFormGroup}
        />
        <NumberField
          source="profile.net_income"
          className={classes.nextFormGroup}
        />
        <NumberField
          source="profile.net_other_income"
          className={classes.nextFormGroup}
        />
        <div />
        <TextField
          source="profile.employment_status"
          className={classes.startFormGroup}
        />
        <TextField
          source="profile.occupation"
          className={classes.nextFormGroup}
        />
        <TextField
          source="profile.employer_name"
          className={classes.nextFormGroup}
        />
        <NumberField
          source="profile.employment_duration_years"
          className={classes.nextFormGroup}
        />
        <div />
        <BooleanField
          source="profile.allow_soft_search"
          className={classes.startFormGroup}
        />
        <DateField
          source="profile.allow_soft_search_timestamp"
          className={classes.nextFormGroup}
        />
        <DateField source="profile.created_at" />
        <DateField source="profile.updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default ProfileShow;
