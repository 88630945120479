import * as React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  DiscreteColorLegend,
} from "react-vis";
import { sortBy } from "lodash";
import AccountStatusMappings from "./AccountStatusMappings";
import AccountTypeMappings from "./AccountTypeMappings";
import { CosmosData } from "./Cosmos";

// generate a consistent graph key using colours from a palette generated using
// the Polychrome for R. The following R script can be run on rdrr.io to
// generate a new palette if necessary.
// library documentation: https://rdrr.io/rforge/Polychrome/
// script (can be run in browser at documentation page):
//
//     library(Polychrome)
//     newpal <- createPalette(28, alphabet.colors(26), M=100000)
//     print(newpal)
//     swatch(newpal)
//
// For more details, see their paper:
// https://www.biorxiv.org/content/10.1101/303883v1.full
const allStatusCodes = Object.keys(AccountStatusMappings).sort();
const colors = [
  "#AD0DFE",
  "#2E82FC",
  "#856616",
  "#751CB1",
  "#555556",
  "#2A865A",
  "#26FD35",
  "#F6E09F",
  "#E2E1E3",
  "#C84726",
  "#DFA0FE",
  "#FC00F9",
  "#3B5C9F",
  "#FBAD1C",
  "#2AC055",
  "#FBA39F",
  "#8CA816",
  "#FD2A3D",
  "#1CFECB",
  "#1CD7FC",
  "#B61CA5",
  "#BC75A3",
  "#FC22BE",
  "#B41669",
  "#F8E016",
  "#FC0D85",
  "#822E16",
  "#B8CAFE",
];

// Ensure that if anyone adds to AccountStatusMappings, we expand the palette to
// accomodate the new entries.
if (allStatusCodes.length > colors.length) {
  throw new Error(
    "AccountStatusMappings has more entries than the CreditGraph color palette can handle - please expand the palette.",
  );
}

const statusColorMap = new Map(
  allStatusCodes.map((value, index) => [value, colors[index]]),
);

interface Props {
  creditReport: CosmosData | null;
}

/**
 * Represents a mapping of account status (see keys of AccountStatusMappings) to
 * the sum of balances of accounts in that status for a given month.
 */
type MonthAccountBalances = { [accountStatus: string]: number };

/**
 * Represents a mapping of months (represented as date strings) to a
 * MonthAccountBalances object summarising account balances for that month.
 */
type AccountBalanceHistory = { [month: string]: MonthAccountBalances };

/**
 * Represents a single point on the account history graph
 */
interface GraphDataPoint {
  x: string;
  y: number;
  label: string;
}

/**
 * Represents a series of points on the account history graph
 */
type GraphDataSeries = GraphDataPoint[];

/**
 * Represents all the data to draw the complete graph
 */
type Graph = GraphDataSeries[];

/**
 * Displays account history as a graph broken down by account status code
 */
export default function CreditGraph({ creditReport }: Props) {
  const [accountTypes, setAccountTypes] = React.useState<
    { title: string; selected: boolean }[]
  >([]);

  // 1. get accounts from the cosmos data
  const accounts =
    creditReport?.Response.ProductResponses.BSBAndCreditReport7[0].Response
      ?.creditreport.applicant[0].accs ?? [];

  // 2. from the sorted accounts:
  //   a) build a map of months to account balances by type
  //   b) build a set of accounts (for some reason?)
  //   c) build a set of account types
  // x values are the month
  // y values are the balance
  const accountData: AccountBalanceHistory = {};
  const accStatusSet: Set<string> = new Set();
  const accTypeSet: Set<string> = new Set();
  accounts.forEach((account) => {
    accTypeSet.add(account.accdetails.acctypecode);
    if (
      accountTypes.length &&
      accountTypes.find(
        (a) => account.accdetails.acctypecode === a.title && a.selected,
      )
    ) {
      account.acchistory.forEach(
        (hist: {
          m: string;
          bal: number;
          limit: number;
          limitSpecified: boolean;
          acc: string;
          pay: string;
        }) => {
          accStatusSet.add(hist.acc);
          if (accountData[hist.m] && accountData[hist.m][hist.acc]) {
            accountData[hist.m][hist.acc] += hist.bal;
          } else if (accountData[hist.m]) {
            accountData[hist.m][hist.acc] = hist.bal;
          } else {
            accountData[hist.m] = {};
            accountData[hist.m][hist.acc] = hist.bal;
          }
        },
      );
    }
  });

  // 3. ensure that the list of account types is up to date... seems a bit late
  //    to do this...
  if (accountTypes.length !== Array.from(accTypeSet).length) {
    setAccountTypes(
      Array.from(accTypeSet).map((s) => ({
        title: s,
        selected: true,
      })),
    );
  }

  // 4. change the map of month -> account data to an array of
  //    { x: month, data: monthAccountData } and then sort it by month
  const allData = Object.keys(accountData)
    .map((key: string) => ({ x: key, data: accountData[key] }))
    .sort((a, b) => (a.x > b.x ? 1 : -1));

  // 5. generate a graph series for each account status type
  const graph: Graph = [];
  accStatusSet.forEach((acc: string) => {
    const graphSeries: Array<{
      x: string;
      y: number;
      label: string;
    }> = [];
    allData.forEach((d) => {
      graphSeries.push({
        x: d.x,
        y: d.data[acc] ?? 0,
        label: acc,
      });
    });
    graph.push(graphSeries);
  });

  // 6. Sort the graph series by their labels to produce a stable layout
  const sortedGraph = sortBy(graph, (graph) => graph[0].label);

  return (
    <>
      <XYPlot height={300} width={900} stackBy="y" xType="ordinal">
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          tickFormat={(v: string) => {
            if (v.search("-12") !== -1) {
              return v;
            }
            return "";
          }}
        />
        <YAxis tickFormat={(v: number) => `${v / 1000}k`} />
        {sortedGraph.map((data) => (
          <VerticalBarSeries
            key={data[0].label}
            color={statusColorMap.get(data[0].label)}
            data={data}
            barWidth={0.9}
          />
        ))}
      </XYPlot>
      <DiscreteColorLegend
        orientation="horizontal"
        items={sortedGraph.map((series) => {
          return {
            title: `${series[0].label} ${
              AccountStatusMappings[series[0].label]
            }`,
            color: statusColorMap.get(series[0].label),
            strokeWidth: 15,
          };
        })}
      />
      <FormControl component="fieldset">
        <FormLabel component="legend">Show Account Types</FormLabel>
        <FormGroup>
          {Array.from(accountTypes).map((series) => (
            <FormControlLabel
              key={series.title}
              control={
                <Checkbox
                  checked={series.selected}
                  onChange={(event) => {
                    setAccountTypes(
                      accountTypes.map((a) =>
                        a.title === event.target.name
                          ? {
                              title: a.title,
                              selected: event.target.checked,
                            }
                          : a,
                      ),
                    );
                  }}
                  name={series.title}
                />
              }
              label={`${series.title} ${AccountTypeMappings[series.title]}`}
            />
          ))}
        </FormGroup>
      </FormControl>
    </>
  );
}
