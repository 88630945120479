import * as React from "react";
import { Route } from "react-router-dom";
import Example from "./Example";
import Campaign from "./Campaigns/Main";
import Collections from "./Collections/Main";

const customRoutes = [
  <Route exact path="/example" component={Example} />,
  <Route exact path="/campaign" component={Campaign} />,
  <Route exact path="/collections" component={Collections} />,
  <Route exact path="/baz" component={Example} noLayout />,
];

export default customRoutes;
