import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  Filter,
  NullableBooleanInput,
  SelectInput,
  BulkExportButton,
  ReferenceField,
  FunctionField,
  Pagination,
  downloadCSV,
} from "react-admin";
import { Chip } from "@material-ui/core";
import jsonExport from "jsonexport/dist";

const LoanFilter = (props: any) => (
  <Filter {...props}>
    <NullableBooleanInput source="all_good" displayNull />
    <SelectInput
      label="Status"
      source="collections_status_filter"
      emptyText="All"
      choices={[
        { id: "GOOD", name: "Good" },
        { id: "CLOSED", name: "Closed" },
      ]}
    />
  </Filter>
);

const LoanStatusBulkActionButtons = (props: any) => (
  <>
    <BulkExportButton {...props} />
  </>
);

const LargePagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 100, 500]} {...props} />
);

const exporter = (records: any, fetchRelatedRecords: any) => {
  // will call dataProvider.getMany('transactions/adminui/connectedbanks', { ids: records.map(record => record.user_id) }), ignoring duplicate and empty user_id
  fetchRelatedRecords(
    records,
    "user_id",
    "transactions/adminui/connectedbanks",
  ).then((connectedbank: any) => {
    console.log("c", connectedbank, records);
    const data = records.map((record: any) => {
      if (connectedbank && connectedbank[record.user_id]) {
        return {
          ...record,
          connectedbanks: connectedbank[record.user_id].data.filter(
            (r: any) => r.bank_linked,
          ).length,
        };
      }
      return { ...record, connectedbanks: 0 };
    });
    console.log("data", data);
    jsonExport(
      data,
      {
        headers: Object.keys(data[0]),
      },
      (err: any, csv: any) => {
        downloadCSV(csv, "loan_status");
      },
    );
  });
};

const LoanStatusList = (props: any) => (
  <List
    {...props}
    filters={<LoanFilter />}
    bulkActionButtons={<LoanStatusBulkActionButtons />}
    pagination={<LargePagination />}
    exporter={exporter}
  >
    <Datagrid rowClick="show">
      <TextField source="external_id" />
      <TextField source="username_cache" />
      <ReferenceField
        label="Banks Connected"
        source="user_id"
        reference="transactions/adminui/connectedbanks"
        sortable={false}
      >
        <FunctionField
          render={(record: any) => {
            if (record) {
              return record.data
                .filter((r: any) => r.bank_linked)
                .map((r: any) => <Chip label={r.name} key={r.id} />);
            }
            return null;
          }}
        />
      </ReferenceField>
      <TextField source="collections_status_filter" />
      <DateField source="first_payment_date" />
      <NumberField source="current_balance" />
      <NumberField source="num_open_months" />
      <NumberField source="num_good_months" />
      <BooleanField source="all_good" sortable={false} />
    </Datagrid>
  </List>
);
export default LoanStatusList;
