import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  BooleanField,
  DateField,
  EmailField,
} from "react-admin";
import useStyles from "./Styles";

type Props = {};

const ProfileShow = (props: Props) => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <EmailField source="email" />
        <TextField source="title" className={classes.startFormGroup} />
        <TextField source="first_name" className={classes.nextFormGroup} />
        <TextField source="family_name" className={classes.nextFormGroup} />
        <DateField source="birthday" />
        <TextField source="phone" />
        <NumberField
          source="customer_income"
          className={classes.startFormGroup}
        />
        <NumberField source="other_income" className={classes.nextFormGroup} />
        <NumberField source="net_income" className={classes.nextFormGroup} />
        <NumberField
          source="net_other_income"
          className={classes.nextFormGroup}
        />
        <div />
        <TextField
          source="employment_status"
          className={classes.startFormGroup}
        />
        <TextField source="occupation" className={classes.nextFormGroup} />
        <TextField source="employer_name" className={classes.nextFormGroup} />
        <NumberField
          source="employment_duration_years"
          className={classes.nextFormGroup}
        />
        <div />
        <BooleanField
          source="allow_soft_search"
          className={classes.startFormGroup}
        />
        <DateField
          source="allow_soft_search_timestamp"
          className={classes.nextFormGroup}
        />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default ProfileShow;
