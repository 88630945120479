export function formatAbbreviated<T>(
  value: number | null | undefined,
  render: (value: string, suffix: string) => T,
): T {
  const suffixes: [string, number][] = [
    ["B", 1e9],
    ["M", 1e6],
    ["k", 1e3],
  ];

  if (value == null) {
    return render("-", "");
  } else {
    const [suffix, threshold] = suffixes.find(
      ([, threshold]) => Math.abs(value) >= threshold,
    ) || ["", 1];

    // Number.prototype.toPrecision() rounds to the nearest significant figure,
    // and we want to always round down (to avoid over-stating values), so we
    // have to do it ourselves...
    const precision = 3;
    const digits = Math.floor(Math.abs(value)).toString().length;
    const v = (
      (Math.floor(value / 10 ** (digits - precision)) *
        10 ** (digits - precision)) /
      threshold
    ).toPrecision(precision);

    return render(v, suffix);
  }
}
