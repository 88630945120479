import * as React from "react";
import { FC, useState, useCallback, MouseEventHandler } from "react";
import { useDataProvider } from "react-admin";
import { useTranslate } from "ra-core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";

interface ConfirmProps {
  cancel?: string;
  confirm?: string;
  confirmColor?: string;
  isOpen?: boolean;
  loading?: boolean;
  onClose: MouseEventHandler;
  onConfirm: MouseEventHandler;
  title: string;
  translateOptions?: object;
}

const ConfirmDialog: FC<ConfirmProps> = (props) => {
  const {
    isOpen,
    loading,
    title,
    children,
    confirm,
    cancel,
    onClose,
    onConfirm,
    translateOptions = {},
  } = props;
  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm(e);
    },
    [onConfirm],
  );
  const translate = useTranslate();
  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);
  return (
    <Dialog
      open={isOpen ? isOpen : false}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {translate(title, { _: title, ...translateOptions })}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          <AlertError />
          {cancel}
        </Button>
        <Button disabled={loading} onClick={handleConfirm} autoFocus>
          <ActionCheck />
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  cancel: "Cancel",
  confirm: "Confirm",
  confirmColor: "primary",
  isOpen: false,
};

interface Props {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  data: any;
}

const SalaryReportDialog = (props: Props) => {
  const [reason, setReason] = useState("");
  const dataProvider = useDataProvider();
  const { onConfirm, data } = props;
  const sendReport = React.useCallback(async () => {
    console.log("sendReport", { reason, ...data });
    await dataProvider.create(
      `transactions/category/categorisation/problem/${data.user_id}`,
      {
        data: { reason, ...data },
      },
    );
    onConfirm();
  }, [dataProvider, onConfirm, reason, data]);

  return (
    <ConfirmDialog
      isOpen={props.open}
      title="Report a problem with salary categorisation"
      confirm="Report"
      onConfirm={sendReport}
      onClose={props.onClose}
    >
      <TextField
        label="Describe the problem"
        multiline
        rows={4}
        value={reason}
        onChange={(e: any) => setReason(e.target.value)}
      />
    </ConfirmDialog>
  );
};

export default SalaryReportDialog;
