import * as React from "react";
import { useLocale } from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  IconButton,
} from "@material-ui/core";
import {
  Error as ErrorIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import { inRange } from "lodash";

const useStyles = makeStyles({
  table: {
    "& td": {
      whiteSpace: "nowrap",
    },
  },
  body: {
    "& > :last-child > td": {
      borderBottom: "none",
    },
  },
  sectionHeading: {
    "& > *": {
      fontWeight: "bold",
    },
  },
  subRow: {
    "& > *": {
      paddingLeft: 32,
      fontStyle: "italic",
    },
  },
  totalRow: {
    borderTop: "2px solid black",
    "& > *": {
      fontWeight: "bold",
    },
  },
  grandTotalRow: {
    borderTop: "3px double black",
  },
  errorIcon: {
    color: red[500],
    verticalAlign: "middle",
  },
  clarification: {
    whiteSpace: "normal",
  },
});

type Income = {
  primary?: number | null;
  secondary?: number | null;
  total: number;
};
type CreditCommitments = {
  cardPayments?: number | null;
  regularPayments?: number | null;
  otherPayments?: number | null;
  total: number;
};
type Expenditure = {
  bills?: number | null;
  essentials?: number | null;
  total: number;
};
type Outgoings = {
  creditCommitments: CreditCommitments | null;
  housingCost: number | null;
  expenditure: Expenditure | null;
  total: number;
  dwellingCostClarification?: string | null;
  essentialsCostClarification?: string | null;
};

interface Column {
  income: Income | null;
  outgoings: Outgoings | null;
  ndi: number;
}

interface NdiTableValues {
  customerDeclared: Column;
  affordabilityCheck: Column;
  tuAffordability: Column;
  fairest: Column;
  final: Column;
}

/**
 * Maps a given function over every Column in a Table, returning an object with
 * the same keys as a table, each of which holding the result of mapping the
 * given function to the value of that Column.
 */
function mapTable<R>(
  f: (column: Column) => R,
  table: NdiTableValues,
): { [Property in keyof NdiTableValues]: R } {
  return {
    customerDeclared: f(table.customerDeclared),
    affordabilityCheck: f(table.affordabilityCheck),
    tuAffordability: f(table.tuAffordability),
    fairest: f(table.fairest),
    final: f(table.final),
  };
}

interface Props {
  table: NdiTableValues;
}

export default function NdiTable({ table }: Props) {
  const classes = useStyles();

  const locale = useLocale();
  const intlFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "GBP",
  });

  /**
   * Helper to print a value as either blank (for null or undefined) or as
   * currency formatted with the intlFormatter.
   */
  const currency = (value: number | null | undefined) =>
    value == null ? "" : intlFormatter.format(value);

  const primaryIncome = (column: Column) => currency(column.income?.primary);
  const secondaryIncome = (column: Column) =>
    currency(column.income?.secondary);
  const totalIncome = (column: Column, totalOk: boolean) => (
    <>
      {currency(column.income?.total)}
      {totalOk ? null : <ErrorIcon className={classes.errorIcon} />}
    </>
  );

  const cardPayments = (column: Column) =>
    currency(column.outgoings?.creditCommitments?.cardPayments);
  const regularPayments = (column: Column) =>
    currency(column.outgoings?.creditCommitments?.regularPayments);
  const otherCreditPayments = (column: Column) =>
    currency(column.outgoings?.creditCommitments?.otherPayments);
  const totalCreditCommitments = (column: Column, totalOk: boolean) => (
    <>
      {currency(column.outgoings?.creditCommitments?.total)}
      {totalOk ? null : <ErrorIcon className={classes.errorIcon} />}
    </>
  );

  const housingCost = (column: Column) =>
    currency(column.outgoings?.housingCost);
  const bills = (column: Column) =>
    currency(column.outgoings?.expenditure?.bills);
  const essentials = (column: Column) =>
    currency(column.outgoings?.expenditure?.essentials);
  const totalExpenditure = (column: Column, totalOk: boolean) => (
    <>
      {currency(column.outgoings?.expenditure?.total)}
      {totalOk ? null : <ErrorIcon className={classes.errorIcon} />}
    </>
  );

  const totalOutgoings = (column: Column, totalOk: boolean) => (
    <>
      {currency(column.outgoings?.total)}
      {totalOk ? null : <ErrorIcon className={classes.errorIcon} />}
    </>
  );

  const ndi = (column: Column, ndiOk: boolean) => (
    <>
      {currency(column.ndi)}
      {ndiOk ? null : <ErrorIcon className={classes.errorIcon} />}
    </>
  );

  /**
   * Check if two values are within a penny of each other - useful in cases
   * where floating point math and rounding errors might make a total off by
   * a penny if the individual values weren't rounded before being summed.
   *
   * Allow a difference of up to £0.01 either way due to fractional pennies.
   * Grumble, grumble... we should use a real decimal type for this...
   */
  const roughlyEqual = (a: number, b: number) => inRange(a, b - 0.01, b + 0.01);

  const checkTotal = (
    values: (number | null | undefined)[],
    total: number | null | undefined,
  ) =>
    values.every((v) => v == null) ||
    (total != null &&
      roughlyEqual(
        values.reduce<number>((a, v) => a + (v ?? 0), 0),
        total,
      ));

  const checkTotalIncom = (column: Column) =>
    checkTotal(
      [column.income?.primary, column.income?.secondary],
      column.income?.total,
    );

  const checkTotalCreditCommitments = (column: Column) =>
    checkTotal(
      [
        column.outgoings?.creditCommitments?.cardPayments,
        column.outgoings?.creditCommitments?.regularPayments,
        column.outgoings?.creditCommitments?.otherPayments,
      ],
      column.outgoings?.creditCommitments?.total,
    );

  const checkTotalExpenditure = (column: Column) =>
    checkTotal(
      [
        column.outgoings?.expenditure?.bills,
        column.outgoings?.expenditure?.essentials,
      ],
      column.outgoings?.expenditure?.total,
    );

  const checkTotalOutgoings = (column: Column) =>
    checkTotal(
      [
        column.outgoings?.creditCommitments?.total,
        column.outgoings?.housingCost,
        column.outgoings?.expenditure?.total,
      ],
      column.outgoings?.total,
    );

  function checkNdi(column: Column) {
    return (
      (column.income?.total == null && column.outgoings?.total == null) ||
      (column.ndi != null &&
        roughlyEqual(
          (column.income?.total ?? 0) - (column.outgoings?.total ?? 0),
          column.ndi,
        ))
    );
  }

  const totalIncomeStatuses = mapTable(checkTotalIncom, table);
  const totalCreditCommitmentsStatuses = mapTable(
    checkTotalCreditCommitments,
    table,
  );
  const totalExpenditureStatuses = mapTable(checkTotalExpenditure, table);
  const totalOutgoingsStatuses = mapTable(checkTotalOutgoings, table);
  const ndiStatuses = mapTable(checkNdi, table);
  const allOk = [
    ...Object.values(totalIncomeStatuses),
    ...Object.values(totalCreditCommitmentsStatuses),
    ...Object.values(totalExpenditureStatuses),
    ...Object.values(totalOutgoingsStatuses),
    ...Object.values(ndiStatuses),
  ].every((v) => v);

  return (
    <>
      {allOk ? null : <TableErrorAlert />}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Customer Declared</TableCell>
            <TableCell>Affordability Check</TableCell>
            <TableCell>TU Affordability</TableCell>
            <TableCell>Fairest</TableCell>
            <TableCell>Final</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          <TableRow className={classes.sectionHeading}>
            <TableCell colSpan={6}>Income</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Primary Income</TableCell>
            <TableCell>{primaryIncome(table.customerDeclared)}</TableCell>
            <TableCell>{primaryIncome(table.affordabilityCheck)}</TableCell>
            <TableCell>{primaryIncome(table.tuAffordability)}</TableCell>
            <TableCell>{primaryIncome(table.fairest)}</TableCell>
            <TableCell>{primaryIncome(table.final)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Secondary Income</TableCell>
            <TableCell>{secondaryIncome(table.customerDeclared)}</TableCell>
            <TableCell>{secondaryIncome(table.affordabilityCheck)}</TableCell>
            <TableCell>{secondaryIncome(table.tuAffordability)}</TableCell>
            <TableCell>{secondaryIncome(table.fairest)}</TableCell>
            <TableCell>{secondaryIncome(table.final)}</TableCell>
          </TableRow>
          <TableRow className={classes.totalRow}>
            <TableCell>Total Income</TableCell>
            <TableCell>
              {totalIncome(
                table.customerDeclared,
                totalIncomeStatuses.customerDeclared,
              )}
            </TableCell>
            <TableCell>
              {totalIncome(
                table.affordabilityCheck,
                totalIncomeStatuses.affordabilityCheck,
              )}
            </TableCell>
            <TableCell>
              {totalIncome(
                table.tuAffordability,
                totalIncomeStatuses.tuAffordability,
              )}
            </TableCell>
            <TableCell>
              {totalIncome(table.fairest, totalIncomeStatuses.fairest)}
            </TableCell>
            <TableCell>
              {totalIncome(table.final, totalIncomeStatuses.final)}
            </TableCell>
          </TableRow>
        </TableBody>
        <TableBody className={classes.body}>
          <TableRow className={classes.sectionHeading}>
            <TableCell colSpan={6}>Outgoings</TableCell>
          </TableRow>
          <TableRow className={classes.subRow}>
            <TableCell>Card Payments</TableCell>
            <TableCell>{cardPayments(table.customerDeclared)}</TableCell>
            <TableCell>{cardPayments(table.affordabilityCheck)}</TableCell>
            <TableCell>{cardPayments(table.tuAffordability)}</TableCell>
            <TableCell>{cardPayments(table.fairest)}</TableCell>
            <TableCell>{cardPayments(table.final)}</TableCell>
          </TableRow>
          <TableRow className={classes.subRow}>
            <TableCell>Regular Payments</TableCell>
            <TableCell>{regularPayments(table.customerDeclared)}</TableCell>
            <TableCell>{regularPayments(table.affordabilityCheck)}</TableCell>
            <TableCell>{regularPayments(table.tuAffordability)}</TableCell>
            <TableCell>{regularPayments(table.fairest)}</TableCell>
            <TableCell>{regularPayments(table.final)}</TableCell>
          </TableRow>
          <TableRow className={classes.subRow}>
            <TableCell>Other Payments</TableCell>
            <TableCell>{otherCreditPayments(table.customerDeclared)}</TableCell>
            <TableCell>
              {otherCreditPayments(table.affordabilityCheck)}
            </TableCell>
            <TableCell>{otherCreditPayments(table.tuAffordability)}</TableCell>
            <TableCell>{otherCreditPayments(table.fairest)}</TableCell>
            <TableCell>{otherCreditPayments(table.final)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Credit Commitments</TableCell>
            <TableCell>
              {totalCreditCommitments(
                table.customerDeclared,
                totalCreditCommitmentsStatuses.customerDeclared,
              )}
            </TableCell>
            <TableCell>
              {totalCreditCommitments(
                table.affordabilityCheck,
                totalCreditCommitmentsStatuses.affordabilityCheck,
              )}
            </TableCell>
            <TableCell>
              {totalCreditCommitments(
                table.tuAffordability,
                totalCreditCommitmentsStatuses.tuAffordability,
              )}
            </TableCell>
            <TableCell>
              {totalCreditCommitments(
                table.fairest,
                totalCreditCommitmentsStatuses.fairest,
              )}
            </TableCell>
            <TableCell>
              {totalCreditCommitments(
                table.final,
                totalCreditCommitmentsStatuses.final,
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {`Housing Cost${table.customerDeclared?.outgoings?.dwellingCostClarification ? '*' : ''}`}
            </TableCell>
            <TableCell>{housingCost(table.customerDeclared)}</TableCell>
            <TableCell>{housingCost(table.affordabilityCheck)}</TableCell>
            <TableCell>{housingCost(table.tuAffordability)}</TableCell>
            <TableCell>{housingCost(table.fairest)}</TableCell>
            <TableCell>{housingCost(table.final)}</TableCell>
          </TableRow>
          {table.customerDeclared?.outgoings?.dwellingCostClarification && (
            <TableRow className={classes.subRow}>
              <TableCell>*Dwelling Cost Clarification</TableCell>
              <TableCell colSpan={5}>
                <Typography variant="body2" className={classes.clarification}>
                  {table.customerDeclared?.outgoings?.dwellingCostClarification}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow className={classes.subRow}>
            <TableCell>Bills</TableCell>
            <TableCell>{bills(table.customerDeclared)}</TableCell>
            <TableCell>{bills(table.affordabilityCheck)}</TableCell>
            <TableCell>{bills(table.tuAffordability)}</TableCell>
            <TableCell>{bills(table.fairest)}</TableCell>
            <TableCell>{bills(table.final)}</TableCell>
          </TableRow>
          <TableRow className={classes.subRow}>
            <TableCell>
              {`Essentials${table.customerDeclared?.outgoings?.essentialsCostClarification ? '*' : ''}`}
            </TableCell>
            <TableCell>{essentials(table.customerDeclared)}</TableCell>
            <TableCell>{essentials(table.affordabilityCheck)}</TableCell>
            <TableCell>{essentials(table.tuAffordability)}</TableCell>
            <TableCell>{essentials(table.fairest)}</TableCell>
            <TableCell>{essentials(table.final)}</TableCell>
          </TableRow>
          {table.customerDeclared?.outgoings?.essentialsCostClarification && (
            <TableRow className={classes.subRow}>
              <TableCell>*Essentials Cost Clarification</TableCell>
              <TableCell colSpan={5}>
                <Typography variant="body2" className={classes.clarification}>
                  {table.customerDeclared?.outgoings?.essentialsCostClarification}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Total Expenditure</TableCell>
            <TableCell>
              {totalExpenditure(
                table.customerDeclared,
                totalExpenditureStatuses.customerDeclared,
              )}
            </TableCell>
            <TableCell>
              {totalExpenditure(
                table.affordabilityCheck,
                totalExpenditureStatuses.affordabilityCheck,
              )}
            </TableCell>
            <TableCell>
              {totalExpenditure(
                table.tuAffordability,
                totalExpenditureStatuses.tuAffordability,
              )}
            </TableCell>
            <TableCell>
              {totalExpenditure(
                table.fairest,
                totalExpenditureStatuses.fairest,
              )}
            </TableCell>
            <TableCell>
              {totalExpenditure(table.final, totalExpenditureStatuses.final)}
            </TableCell>
          </TableRow>
          <TableRow className={classes.totalRow}>
            <TableCell>Total Outgoing</TableCell>
            <TableCell>
              {totalOutgoings(
                table.customerDeclared,
                totalOutgoingsStatuses.customerDeclared,
              )}
            </TableCell>
            <TableCell>
              {totalOutgoings(
                table.affordabilityCheck,
                totalOutgoingsStatuses.affordabilityCheck,
              )}
            </TableCell>
            <TableCell>
              {totalOutgoings(
                table.tuAffordability,
                totalOutgoingsStatuses.tuAffordability,
              )}
            </TableCell>
            <TableCell>
              {totalOutgoings(table.fairest, totalOutgoingsStatuses.fairest)}
            </TableCell>
            <TableCell>
              {totalOutgoings(table.final, totalOutgoingsStatuses.final)}
            </TableCell>
          </TableRow>
        </TableBody>
        <TableBody className={classes.body}>
          <TableRow
            className={[classes.totalRow, classes.grandTotalRow].join(" ")}
          >
            <TableCell>NDI</TableCell>
            <TableCell>
              {ndi(table.customerDeclared, ndiStatuses.customerDeclared)}
            </TableCell>
            <TableCell>
              {ndi(table.affordabilityCheck, ndiStatuses.affordabilityCheck)}
            </TableCell>
            <TableCell>
              {ndi(table.tuAffordability, ndiStatuses.tuAffordability)}
            </TableCell>
            <TableCell>{ndi(table.fairest, ndiStatuses.fairest)}</TableCell>
            <TableCell>{ndi(table.final, ndiStatuses.final)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

function TableErrorAlert() {
  const [expanded, setExpanded] = React.useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Alert
      severity="error"
      action={
        <IconButton
          style={{ alignSelf: "flex-start" }}
          color="inherit"
          size="small"
          onClick={handleClick}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      }
    >
      <AlertTitle>This NDI table has errors</AlertTitle>
      <Collapse in={expanded}>
        It looks like there are one or more errors in this table, which have
        been marked with an icon. This can be a result of one of the following
        things:
        <ol>
          <li>
            <b>
              You are viewing an old decision with a newer version of the
              Underwriter UI.
            </b>
            <p>
              Older decisions don't always provide enough intermediary values in
              their outputs for the Underwriter UI to reproduce and check the
              maths. Also sometimes values are added or removed as we change our
              affordability checks, and the UI is only capable of rendering and
              checking the most recent affordabiltiy checks.
            </p>
          </li>
          <li>
            <b>
              There is an error in the Underwriting UI's checks for the values
              in this table
            </b>
            <p>
              If the Underwriter UI is not accounting for a particular decision
              path or set of values, then errors will be spuriously displayed
              here despite the final NDI value used by the decision actually
              being correct. Due to the nature of this issue, it's impossible
              for the Underwriter UI to determine for itself whether the
              decision output or the UI's own checks are incorrect.
            </p>
          </li>
          <li>
            <b>There is a real error in either the decision logic.</b>
            <p>
              The maths for all values in this table is performed in by our
              decision engine and the Underwriter UI simply displays the values
              stored in the decision output and then checks the maths. If the
              maths doesn't check out, then there could be a genuine issue with
              our decision logic that will need to be diagnosed, corrected and
              have its impact assessed.
            </p>
          </li>
        </ol>
        <p>
          If you are viewing an application that is still in progress, usually
          you can update the decision and then refresh this page to resolve
          these issues. If this error still remains after updating the decision
          and refreshing the page, then there is either an error in our decision
          logic or an error in the Underwriting UI. In either case, you should
          raise a ticket to have the situation resolved.
        </p>
      </Collapse>
    </Alert>
  );
}
