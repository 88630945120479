import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Button, Confirm, DataProviderContext, Identifier, Record, useNotify, useRefresh} from "react-admin";
import UpdraftDataProvider from "../UpdraftDataProvider";
import {FormHelperText} from "@material-ui/core";


const SendCommsDialogSelectInner = ({ application }: any) => {

  const comms: {[index: string]: string} = {
    "income_lower_than_stated": "Income_Verification",
    "income_not_visible": "Income_Verification",
    "multiple_income": "Income_Verification",
    "second_income": "Income_Verification",
    "bank_link_expired": "Income_Verification",
    "open_banking_failed": "Income_Verification",
    "self_employed": "Income_Verification",

    "bureau_stress_payment_arrangement": "Bureau_Stress",
    "bureau_stress_delq": "Bureau_Stress",
    "bureau_stress_payment_holiday": "Bureau_Stress",
    "bureau_stress_default": "Bureau_Stress",

    "id_checks_photo": "ID_Checks",
    "id_checks_photo_and_poa": "ID_Checks",
    "id_checks_proof_of_address": "ID_Checks",
  }

  const groups = Object.keys(comms).map((key) => comms[key])
                  .filter((v, i, self) => self.indexOf(v) === i)

  const get_group_items = (group: string) => Object.keys(comms).map((key) => [key, comms[key]])
                          .filter(item => item[1] === group).map(item => item[0])

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [selected, setSelected] = React.useState('');
  const selectionChangeHandler = (event: any) => {
    setSelected(event.target.value);
  };
  const [selectError, setSelectError] = React.useState(false);

  const refresh = useRefresh();
  const notify = useNotify();

  const useTriggerSendComms = (
    applicationId: Identifier,
  ): [
    (e: any) => Promise<void>,
    { loading: boolean; loaded: boolean; error: Error | null},
  ] => {
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [error, setError] = React.useState<Error | null>(null);

    const dataProvider = React.useContext(
      DataProviderContext,
    ) as UpdraftDataProvider;

    const triggerSendComms = React.useCallback(async (selected: string) => {
      setLoading(true);
      setLoaded(false);
      setError(null);

      try {
        await dataProvider.request("PUT", "loans/loans/application", {
          id: applicationId,
          action: "trigger_" + comms[selected].toLowerCase(),
          filter: {"type": selected}
        });
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          setError(new Error(String(error)));
        }
      } finally {
        setLoading(false);
        setLoaded(true);
      }

    }, [applicationId, setLoading, setLoaded, setError, dataProvider]);

    return [triggerSendComms, {loading, loaded, error}];
  }

  const [
    triggerSendComms,
    { loading, loaded, error /*, validationError*/ },
  ] = useTriggerSendComms(application.id);

  const handleConfirm = async (selected: string) => {
    if (!selected) {
      setSelectError(true)
      setOpen(true)
    } else {
      await triggerSendComms(selected)
    }
  }

  const handleClose = () => {
    setSelectError(false)
    setOpen(false)
  }

  React.useEffect(() => {
    refresh();
  }, [refresh, loading]);

  React.useEffect(() => {
    if (loaded) {
      if (error) {
        notify(
          "An unexpected error occurred when sending affordability comms. Please report this issue to engineering.",
          "error",
        );
      } else {
        setOpen(false);
      }
    }
  }, [error, loaded, notify]);
  return (
    <>
      <Button label="Send Other Comms" onClick={handleClickOpen}/>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Send Income Verification, Bureau Stress, and ID Checks Comms"
        confirm={loading ? "Working..." : "Confirm"}
        content={
          <form>
            <FormControl required>
              <InputLabel htmlFor="grouped-native-select">Comms</InputLabel>
              <Select error={selectError}
                variant="filled" native defaultValue="" id="grouped-native-select" value={selected} onChange={selectionChangeHandler}>
                <option aria-label="None" value="" />
                {groups.map((group) => (
                  <optgroup label={group.replaceAll("_", " ")}>
                    {get_group_items(group).map((name) => (
                      <option key={name} value={name}>
                        {name.replaceAll("_", " ")}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>
              {selectError && <FormHelperText>Select Comms to send!</FormHelperText>}
            </FormControl>
          </form>
        }
        onConfirm={() => handleConfirm(selected)}
        onClose={handleClose}
      />
    </>
  );
}

export default function SendCommsDialogSelect({application,}: {
  application: Record | null | void;
}) {
  return application == null ? (
    <Button label="Send Income" disabled />
  ) : (
    <SendCommsDialogSelectInner application={application} />
  );
}

