import * as React from "react";
import clsx from "clsx";
import { AppBar, Layout, LayoutProps, AppBarProps } from "react-admin";
import useStyles from "./styles";
import { DetailDrawerManager, useIsDrawerOpen } from "./DetailDrawer";

/**
 * Custom react-admin `Layout` that makes space for a "more details" view
 * implemented as a meterial-ui `Drawer` (@see `DetailDrawer`).
 *
 * @param props standard props of react-admin's `Layout` component
 */
export default function LayoutWithDetailDrawer(props: LayoutProps) {
  return (
    <DetailDrawerManager>
      <LayoutInner {...props} />
    </DetailDrawerManager>
  );
}

function LayoutInner({ children, ...otherProps }: LayoutProps) {
  const classes = useStyles();
  const isDrawerOpen = useIsDrawerOpen();

  return (
    <Layout appBar={AppBarWithDrawer} {...otherProps}>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: isDrawerOpen,
        })}
      >
        {children}
      </div>
    </Layout>
  );
}

/**
 * Custom react-admin `AppBar` that makes space for a "more details" view
 * implemented as a meterial-ui `Drawer` (@see `DetailDrawer`).
 *
 * @param props standard props of react-admin's `AppBar` component
 */
function AppBarWithDrawer({ className, ...otherProps }: AppBarProps) {
  const classes = useStyles();
  const isDrawerOpen = useIsDrawerOpen();

  return (
    <AppBar
      className={clsx(className, classes.appBar, {
        [classes.appBarShift]: isDrawerOpen,
      })}
      {...otherProps}
    />
  );
}
