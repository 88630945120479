import * as React from "react";
import {
  required,
  TextField,
  FunctionField,
  LinearProgress,
  TextInput,
  NumberInput,
  Button,
  SaveButton,
  DeleteButton,
  useLocale,
  useGetManyReference,
  useCreate,
  useNotify,
  useUpdate,
  FormWithRedirect,
} from "react-admin";
import { Typography, makeStyles } from "@material-ui/core";
import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  useLoanApplication,
  useDecisionDataSourceOutput,
} from "../resourceHooks";
import { isEqual } from "lodash";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
  noPadding: {
    padding: 0,
  },

  fauxTableRow: {
    "& > div": {
      display: "flex",
      flexDirection: "row",
    },

    "& label": {
      minWidth: 280,
      padding: "8px 20px 4px 0px",

      // Strip away the transform from MUI's shrink class
      transform: "none",

      fontSize: 14,
    },

    // We don't have a very reliable way of targetting the value content... :/
    "& > div > div": {
      width: "auto",
      fontSize: 14,
    },
  },
});

export default function AffordabilityOverrideArea({ applicationId }: any) {
  const styles = useStyles();
  const locale = useLocale();
  const currencyFormat = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "GBP",
  });

  // Get the affordability override as it was used in the decision in zoral
  const {
    data: loanApplication,
    loading: loanApplicationLoading,
    refetch: refetchLoanApplication,
  } = useLoanApplication(applicationId);

  const {
    data: affordabilityOverrideDataSourceOutput,
    isLoading: affordabilityOverrideDataSourceLoading,
  } = useDecisionDataSourceOutput(
    loanApplication?.offer_review,
    "AffordabilityOverride",
    { enabled: loanApplication?.offer_review != null }, // only make the call once we have an offer_review to fetch.
  );

  // we don't load the data source until the application is loaded, so the true
  // loading state for the data source is if _either_ is loading...
  const affordabilityOverrideDataSourceAggregateLoading =
    loanApplicationLoading || affordabilityOverrideDataSourceLoading;

  // get the actual state of the affordability override in loans.api
  const {
    data: affordabilityOverrides,
    ids,
    loading,
    refetch: refetchAffordabilityOverrides,
  } = useGetManyReference(
    "loans/loans/affordabilityoverride",
    "application",
    applicationId,
    { page: 1, perPage: 10 },
    { field: "created_at", order: "DESC" },
    {},
    "loans/adminui/application",
  );

  if (loading || applicationId == null) {
    return <LinearProgress />;
  }

  const affordabilityOverrideFromApi =
    ids.length > 0 ? affordabilityOverrides[ids[0]] : null;
  const affordabilityOverrideFromZoral =
    Array.isArray(affordabilityOverrideDataSourceOutput) &&
    affordabilityOverrideDataSourceOutput.length > 0
      ? affordabilityOverrideDataSourceOutput[0]
      : null;

  // if the data source has loaded from zoral, we can display a banner if it
  // doesn't match the state of the API, warning the underwriter to update the
  // decision appropriately...
  const apiAndZoralOutOfSync =
    !affordabilityOverrideDataSourceAggregateLoading &&
    !isEqual(affordabilityOverrideFromApi, affordabilityOverrideFromZoral);

  const warningBanner = apiAndZoralOutOfSync ? (
    <Alert severity="error">
      This override data is out of sync with the data found in the decision
      report from Zoral. Refresh this page and, if that does not clear this
      warning, then update the decision for this application.
    </Alert>
  ) : null;

  function handleSuccess() {
    // No need to refetch the offer review output - decisions are immutable so,
    // if that has changed then a new decision id will appear in the application
    // and that will automatically be fetched by the hooks above...
    refetchLoanApplication();
    refetchAffordabilityOverrides();
  }

  if (affordabilityOverrideFromApi != null) {
    return (
      <BoxedShowLayout
        resource="loans/loans/affordabilityoverride"
        record={affordabilityOverrideFromApi}
        className={styles.noPadding}
      >
        <RaBox
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography variant="h6">Affordability Override</Typography>
          <RaBox display="flex" flexDirection="row">
            <AffordabilityOverrideUpdateOrCreateButton
              existingRecord={affordabilityOverrideFromApi}
              onSuccess={handleSuccess}
            />
            <DeleteButton
              undoable={false}
              confirmTitle="Delete affordability override?"
              redirect={false}
              onSuccess={handleSuccess}
            />
          </RaBox>
        </RaBox>

        {warningBanner}
        <FunctionField
          // our api returns decimals as strings, so NumberField won't work here
          className={styles.fauxTableRow}
          source="net_monthly_income"
          render={(record: any) => {
            return record == null
              ? null
              : currencyFormat.format(parseFloat(record.net_monthly_income));
          }}
        />
        <TextField
          className={styles.fauxTableRow}
          source="net_monthly_income_notes"
          style={{ whiteSpace: "pre-wrap" }} // support multiline values
        />
      </BoxedShowLayout>
    );
  }

  return (
    <RaBox>
      <RaBox display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6">Affordability Override</Typography>
        <AffordabilityOverrideUpdateOrCreateButton
          applicationId={applicationId}
          onSuccess={handleSuccess}
        />
      </RaBox>
      {/*Wrap this in a fragment because RaBox is dumb and tries to clone it when it might be null*/}
      <>{warningBanner}</>
      <em>(no affordability override)</em>
    </RaBox>
  );
}

function AffordabilityOverrideUpdateOrCreateButton({
  applicationId,
  existingRecord,
  onSuccess,
}: any) {
  const isUpdate = existingRecord != null;
  const RESOURCE = "loans/loans/affordabilityoverride";

  const [showDialog, setShowDialog] = React.useState(false);
  const [create, { loading: createLoaing }] = useCreate(RESOURCE);
  const [update, { loading: updateLoading }] = useUpdate(
    RESOURCE,
    existingRecord?.id,
  );
  const loading = createLoaing || updateLoading;
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = (
    event: {},
    reason?: "backdropClick" | "escapeKeyDown",
  ) => {
    // don't close because we clicked outside the modal - it just results in lost work :(
    if (reason !== "backdropClick") {
      setShowDialog(false);
    }
  };

  const handleSubmit = async (values: any) => {
    const handler = isUpdate ? update : create;
    return new Promise((resolve, reject) => {
      handler(
        { payload: { data: { application: applicationId, ...values } } },
        {
          onSuccess: ({ data }: any) => {
            setShowDialog(false);
            onSuccess();
            resolve(null);
          },
          onFailure: ({ error }: any) => {
            notify(error.message, "error");
            reject();
          },
        },
      );
    });
  };

  const dialogTitle = isUpdate
    ? "Edit affordability override"
    : "Create affordability override";

  return (
    <>
      <Button
        onClick={handleClick}
        label={isUpdate ? "Edit Override" : "Add Override"}
      >
        {isUpdate ? <EditIcon /> : <AddIcon />}
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={dialogTitle}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>

        <FormWithRedirect
          resource={RESOURCE}
          initialValues={existingRecord}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving, invalid }) => (
            <>
              <DialogContent>
                <NumberInput
                  source="net_monthly_income"
                  step={0.01}
                  min={0}
                  validate={required()}
                />
                <TextInput
                  source="net_monthly_income_notes"
                  fullWidth
                  multiline
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <CancelIcon />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={pristine || loading || invalid}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}
