import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Filter,
  TextInput,
  SelectInput,
  Pagination,
  useQueryWithStore,
} from "react-admin";
import UpdateCategoriesButton from "./UpdateCategoriesButton";
type Props = {};

const BulkActionButtons = (props: any) => (
  <>
    <UpdateCategoriesButton label="Update Categories" {...props} />
  </>
);

const TransactionFilter = (props: any) => {
  const { data } = useQueryWithStore({
    type: "getOne",
    resource: "transactions/adminui/account",
    // Hack to re-use the getOne API here
    payload: { id: "meta" },
  });
  const cat_1 = data?.cat_1.map((c: any) => ({ id: `${c}`, name: c }));
  const cat_2 = data?.cat_2.map((c: any) => ({ id: `${c}`, name: c }));
  const class_1 = data?.class_1.map((c: any) => ({ id: `${c}`, name: c }));
  return (
    <Filter {...props}>
      <TextInput
        label="User"
        type="email"
        source="bank_account__bank_profile__user__email"
        alwaysOn
      />
      <TextInput label="Search" source="search" />
      <SelectInput
        label="Cat 1"
        source="spend_cat_1"
        emptyText="All"
        choices={cat_1}
      />
      <SelectInput
        label="Cat 2"
        source="spend_cat_2"
        emptyText="All"
        choices={cat_2}
        alwaysOn
      />
      <SelectInput
        label="Class 1"
        source="spend_class_1"
        emptyText="All"
        choices={class_1}
        alwaysOn
      />
    </Filter>
  );
};

const MidPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[50, 100]} perPage={50} {...props} />
);

const accounttransactionList = (props: Props) => (
  <List
    {...props}
    filters={<TransactionFilter />}
    sort={{ field: "timestamp", order: "DESC" }}
    bulkActionButtons={<BulkActionButtons />}
    pagination={<MidPagination />}
  >
    <Datagrid rowClick="show">
      <DateField source="timestamp" />
      <TextField source="description" />
      <TextField source="transaction_type" />
      <TextField source="transaction_category" />
      <TextField source="amount" />
      <TextField source="balance" />
      <TextField source="spend_cat_1" />
      <TextField source="spend_cat_2" />
      <TextField source="spend_class_1" />
      <TextField source="spend_merchant" />
      <ReferenceField
        source="bank_account"
        reference="transactions/adminui/bankaccounts"
        link="show"
      >
        <TextField source="display_name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default accounttransactionList;
