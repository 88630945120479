import * as React from "react";
import { DataProviderContext } from "react-admin";
import UpdraftDataProvider from "./UpdraftDataProvider";

export interface UnderwriterContextValues {
  /**
   * The base url of the Zoral console, including a trailing slash but not the
   * anchor portion (which Zoral uses for its client-side routing).
   *
   * e.g. if the Zoral dashboard URL is "https://zoral.example.com/#/dashboard"
   * then this value should be "https://zoral.example.com/"
   */
  zoralConsoleBaseUrl: string;
}

const UnderwriterContext = React.createContext<UnderwriterContextValues>({
  zoralConsoleBaseUrl: "about:blank", // unused default.
});

interface UnderwriterProviderProps extends UnderwriterContextValues {
  children: React.ReactNode;
}

export function UnderwriterContextProvider({
  children,
  ...values
}: UnderwriterProviderProps) {
  return (
    <UnderwriterContext.Provider value={values}>
      {children}
    </UnderwriterContext.Provider>
  );
}

/**
 * Returns a function that will format a Zoral decision report URL for the given
 * request ID.
 */
export function useFormatZoralReportUrl() {
  const { zoralConsoleBaseUrl } = React.useContext(UnderwriterContext);

  return React.useCallback(
    (requestId: string) =>
      `${zoralConsoleBaseUrl}#/report/decision/${requestId}`,
    [zoralConsoleBaseUrl],
  );
}

export function useFormatLoansApiAdminUrl() {
  const dataProvider = React.useContext(
    DataProviderContext,
  ) as UpdraftDataProvider;

  const loansBaseUrl = dataProvider.getServiceUrl("loans");

  return React.useCallback(
    (module: string, model: string, id?: number) => {
      const listUrl = `${loansBaseUrl}/admin/${module}/${model}`;

      if (id == null) {
        return listUrl;
      } else {
        return `${listUrl}/${id}/change`;
      }
    },
    [loansBaseUrl],
  );
}
