import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
} from "react-admin";

const ListFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="search" alwaysOn />
  </Filter>
);

const MandateShow = (props: any) => (
  <List
    {...props}
    filters={<ListFilter />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="externalReference" />
      <TextField source="status" />
      <TextField source="modulr_status" />
      <DateField source="modulr_status_updated" />
      <TextField source="name" />
      <TextField source="accountNumber" />
      <TextField source="sortCode" />
      <TextField source="modulrId" />
    </Datagrid>
  </List>
);

export default MandateShow;
