import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  EmailField,
  ReferenceField,
} from "react-admin";

type Props = {};

const ProfileList = (props: Props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <EmailField source="email" />
      <TextField source="title" />
      <TextField source="first_name" />
      <TextField source="family_name" />
      <NumberField source="customer_income" />
      <NumberField source="other_income" />
      <ReferenceField
        source="affordability"
        reference="loans/adminui/affordability"
        link="show"
      >
        <NumberField source="total" />
      </ReferenceField>
      <TextField source="employment_status" />
      <TextField source="occupation" />
      <TextField source="employer_name" />
      <NumberField source="employment_duration_years" />
      <BooleanField source="allow_soft_search" />
      <DateField source="birthday" />
      <TextField source="phone" />
      <NumberField source="net_income" />
      <NumberField source="net_other_income" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <DateField source="allow_soft_search_timestamp" />
    </Datagrid>
  </List>
);

export default ProfileList;
