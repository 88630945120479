import * as React from "react";
import {
  Button,
  Confirm,
  ReferenceField,
  FunctionField,
  useLocale,
  useRefresh,
  useNotify,
  DataProviderContext,
  Identifier,
  Record,
} from "react-admin";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import UpdraftDataProvider from "../UpdraftDataProvider";
import ApplicationDecisionResultCode from "./ApplicationDecisionResultCode";

function useTriggerAffordabilityCheck(
  applicationId: Identifier,
): [
  () => Promise<void>,
  { loading: boolean; loaded: boolean; error: Error | null },
] {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  const dataProvider = React.useContext(
    DataProviderContext,
  ) as UpdraftDataProvider;

  const triggerAffordabilityCheck = React.useCallback(async () => {
    setLoading(true);
    setLoaded(false);
    setError(null);

    try {
      await dataProvider.request("PUT", "loans/loans/application", {
        id: applicationId,
        action: "trigger_affordability_check",
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error(String(error)));
      }
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  }, [applicationId, setLoading, setLoaded, setError, dataProvider]);

  return [triggerAffordabilityCheck, { loading, loaded, error }];
}

export default function AffordabilityCheckButton({
  application,
}: {
  application: Record | null | void;
}) {
  return application == null ? (
    <Button label="Send Affordability" disabled />
  ) : (
    <AffordabilityCheckButtonInner application={application} />
  );
}

function AffordabilityCheckButtonInner({ application }: any) {
  const locale = useLocale();
  const intlFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "GBP",
  });

  /**
   * Helper to print a value as either blank (for null or undefined) or as
   * currency formatted with the intlFormatter.
   */
  const currency = (value: number | null | undefined) =>
    value == null ? "" : intlFormatter.format(value);

  const [isOpen, setIsOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const [
    triggerAffrodabilityCheck,
    { loading, loaded, error },
  ] = useTriggerAffordabilityCheck(application.id);

  React.useEffect(() => {
    refresh();
  }, [refresh, loading]);

  React.useEffect(() => {
    if (loaded) {
      if (error) {
        notify(
          "An unexpected error occurred when sending affordability comms. Please report this issue to engineering.",
          "error",
        );
      } else {
        setIsOpen(false);
      }
    }
  }, [error, loaded, notify]);

  return (
    <>
      <Button label="Send Affordability" onClick={() => setIsOpen(true)} />
      <Confirm
        isOpen={isOpen}
        loading={loading}
        title="Send Affordability Comms"
        confirm={loading ? "Working..." : "Confirm"}
        content={
          <div>
            <ReferenceField
              label=""
              source="offer_review"
              reference="loans/zoral/decisions"
              link={false}
            >
              <FunctionField
                render={(record: any) => {
                  if (record == null) {
                    return null;
                  } else if (
                    record != null &&
                    record.raw_response.Reasons.some(
                      (r: any) =>
                        r.Code ===
                        ApplicationDecisionResultCode.SalaryNotConfirmed,
                    )
                  ) {
                    return (
                      <Box mb={2}>
                        <Alert severity="warning">
                          The salary of this applicant has not yet been
                          confirmed. These values therefore show an estimate of
                          the net monthly salary based on the customer's
                          declared annual salary.
                        </Alert>
                      </Box>
                    );
                  }
                }}
              />
            </ReferenceField>
            Are you sure that you want to send affordability comms for this
            application? The following values will be used:
            <ReferenceField
              label=""
              source="offer_review"
              reference="loans/zoral/decisions"
              link={false}
            >
              <FunctionField
                render={(offerReview: any) => {
                  const output = offerReview?.raw_response?.WorkflowOutput;
                  return (
                    <ul>
                      <li>
                        <b>Total Income: </b>
                        {currency(
                          output?.AffordabilityCheckNetMonthlyTotalIncome,
                        )}
                      </li>
                      <li>
                        <b>Card Payments: </b>
                        {currency(output?.AffordabilityCheckCardPayments)}
                      </li>
                      <li>
                        <b>Regular Payments: </b>
                        {currency(output?.AffordabilityCheckRegularPayments)}
                      </li>
                      <li>
                        <b>Total Credit Commitment: </b>
                        {currency(output?.AffordabilityCheckCreditCommitment)}
                      </li>
                      <li>
                        <b>Housing Cost: </b>
                        {currency(output?.AffordabilityCheckHousingCost)}
                      </li>
                      <li>
                        <b>Bills: </b>
                        {currency(output?.AffordabilityCheckBills)}
                      </li>
                      <li>
                        <b>Essentials: </b>
                        {currency(output?.AffordabilityCheckEssentials)}
                      </li>
                      <li>
                        <b>Expenditure: </b>
                        {currency(output?.AffordabilityCheckExpenditure)}
                      </li>
                      <li>
                        <b>Total Outgoings: </b>
                        {currency(output?.AffordabilityCheckTotalOutgoings)}
                      </li>
                      <li>
                        <b>NDI: </b>
                        {currency(output?.AffordabilityCheckNDI)}
                      </li>
                    </ul>
                  );
                }}
              />
            </ReferenceField>
          </div>
        }
        onConfirm={triggerAffrodabilityCheck}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}
