import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField,
  ArrayField,
  Datagrid,
} from "react-admin";

type Props = {};

const SalaryShow = (props: Props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source="salary" />
      <NumberField source="confidence" />
      <ArrayField source="transactions">
        <Datagrid>
          <DateField source="timestamp" />
          <NumberField source="amount" />
          <TextField source="description" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="matched_transactions">
        <Datagrid>
          <DateField source="timestamp" />
          <NumberField source="amount" />
          <TextField source="description" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

const Salary = { show: SalaryShow };

export default Salary;
