import * as React from "react";
import { Record, useRecordContext } from "react-admin";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import classNames from "classnames";
import { get, identity } from "lodash";
import copy from "copy-to-clipboard";
import cx from "classnames";

const menuRowPaddingInEm = 0.5;

const useStyles = makeStyles((theme) => ({
  field: {
    position: "relative",
    margin: `${-menuRowPaddingInEm}em`,
    display: "block",
    zIndex: 5,
    "&:not(:hover) $icon": {
      display: "none",
    },
    "& button": {
      width: "100%",
      display: "block",
      padding: `${menuRowPaddingInEm}em`,
      textTransform: "none",
    },
  },
  icon: {
    height: "40%",
    position: "absolute",
    top: "0.2em",
    zIndex: 10,
    padding: "0.2em",
    backgroundColor: theme.palette.background.default,
  },
  iconRight: {
    right: "-1.5em",
  },
  iconLeft: {
    left: "-1.5em",
  },
}));

type Props<RecordType> = {
  label?: string;
  className?: string;
  record?: RecordType;
  source: string;
  getTextForCopy: (RecordType: any) => string;
  iconPosition: "left" | "right" | "none";
};

export default function ClipboardListField<RecordType extends Record>(
  props: Props<RecordType>,
) {
  const { className, source, getTextForCopy, iconPosition } = props;
  const classes = useStyles();
  const record = useRecordContext(props);
  const [wasCopied, setWasCopied] = React.useState(false);
  const value = get(record, source);
  const text = getTextForCopy(value);

  const handleCopy = React.useCallback(() => {
    copy(text);
    setWasCopied(true);
  }, [setWasCopied, text]);

  return (
    <div
      className={cx(classes.field, className)}
      onMouseLeave={() => setWasCopied(false)}
    >
      {iconPosition !== "none" && (
        <SvgIcon
          className={classNames(classes.icon, {
            [classes.iconRight]: iconPosition === "right",
            [classes.iconLeft]: iconPosition === "left",
          })}
          component={wasCopied ? DoneOutlineIcon : FileCopyOutlinedIcon}
        />
      )}
      <Button onClick={handleCopy}>{text}</Button>
    </div>
  );
}

ClipboardListField.defaultProps = {
  getTextForCopy: identity,
  iconPosition: "right",
  addLabel: true,
};
