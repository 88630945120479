import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  Datagrid,
  BooleanField,
  FunctionField,
} from "react-admin";

type Props = {};

const DecisionShow = (props: Props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="status" />
      <TextField source="raw_response.RequestId" />
      <TextField source="raw_response.VerificationStatus" />
      <TextField source="raw_response.WorkflowName" />
      <TextField source="status" />
      <DateField source="updated_at" />
      <BooleanField source="is_current" />
      <ArrayField source="raw_response.Reasons">
        <Datagrid>
          <TextField source="Code" />
          <TextField source="Description" />
          <BooleanField source="Suppressed" />
          <BooleanField source="Delayed" />
          <TextField source="Category" />
        </Datagrid>
      </ArrayField>
      <FunctionField
        label="WorkflowOutput"
        render={(record: any) =>
          `${JSON.stringify(record?.raw_response.WorkflowOutput, null, 2)}`
        }
      />
      <BooleanField source="is_old" />
      <BooleanField source="is_valid" />
      <ArrayField source="raw_response.DecisionResults">
        <Datagrid>
          <TextField source="IsRandom" />
          <TextField source="Name" />
          <BooleanField source="Status" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default DecisionShow;
