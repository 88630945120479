import * as React from "react";
import { useVersion, useDataProvider } from "react-admin";
import { Box, Card, CardHeader } from "@material-ui/core";
import EmojiPeople from "@material-ui/icons/EmojiPeople";
import CardWithIcon from "../Dashboard/CardWithIcon";
import Spacer from "./Spacer";

interface Metrics {
  most_recent_activity: string | null;
  count: number;
}

interface LoanStatusMetrics {
  collections_status_filter: string;
  count: number;
}

interface State {
  metrics: Array<Metrics>;
  collections_status_metrics: Array<LoanStatusMetrics>;
}

const mapTitle = (short: string | null): string => {
  switch (short) {
    case "RPC":
      return "Right Party Contact";
    case "PTK":
      return "Promise Taken";
    case "PBK":
      return "Promise Broken";
    case "PKP":
      return "Promise Kept";
    case null:
      return "None";
    default:
      return short;
  }
};

export default () => {
  const [state, setState] = React.useState<State>({
    metrics: [],
    collections_status_metrics: [],
  });
  const version = useVersion();
  const dataProvider = useDataProvider();
  const fetchStats = React.useCallback(async () => {
    const data = await dataProvider.getOne("vault/collection/metrics", {
      id: "status",
    });
    const newState = {
      metrics: data.data.metrics,
    };
    setState((prevState: State) => {
      return { ...prevState, ...newState };
    });
  }, [dataProvider]);
  const fetchLoanStats = React.useCallback(async () => {
    const data = await dataProvider.getOne("vault/adminui/loan/metrics", {
      id: "status",
    });
    console.log(data);
    const newState = {
      collections_status_metrics: data.data.collections_status_metrics,
    };
    setState((prevState: State) => {
      return { ...prevState, ...newState };
    });
  }, [dataProvider]);
  React.useEffect(() => {
    fetchStats();
    fetchLoanStats();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Card>
      <CardHeader title="Collections Management" />
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        <Spacer flexBasis={200} />
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {state.metrics
          .filter((metric) => metric.most_recent_activity)
          .map((metric) => (
            <CardWithIcon
              to="/"
              icon={EmojiPeople}
              title={`${mapTitle(metric.most_recent_activity)}`}
              subtitle={metric.count}
              suffix=""
            />
          ))}
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {state.collections_status_metrics
          .filter((metric) =>
            ["XDAYS", "1_CYCLE", "2_CYCLE", "OVER_3_LATE"].includes(
              metric.collections_status_filter,
            ),
          )
          .map((metric) => (
            <CardWithIcon
              to="/"
              icon={EmojiPeople}
              title={`${metric.collections_status_filter}`}
              subtitle={metric.count}
              suffix=""
            />
          ))}
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {state.collections_status_metrics
          .filter((metric) =>
            ["EMERGENCY_PAYMENT_FREEZE",].includes(
              metric.collections_status_filter,
            ),
          )
          .map((metric) => (
            <CardWithIcon
              to="/"
              icon={EmojiPeople}
              title={`${metric.collections_status_filter}`}
              subtitle={metric.count}
              suffix=""
            />
          ))}
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {state.collections_status_metrics
          .filter((metric) => metric.collections_status_filter.startsWith("PA"))
          .map((metric) => (
            <CardWithIcon
              to="/"
              icon={EmojiPeople}
              title={`${metric.collections_status_filter}`}
              subtitle={metric.count}
              suffix=""
            />
          ))}
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {state.collections_status_metrics
          .filter((metric) =>
            ["GOOD", "CLOSED", "WRITTEN_OFF"].includes(
              metric.collections_status_filter,
            ),
          )
          .map((metric) => (
            <CardWithIcon
              to="/"
              icon={EmojiPeople}
              title={`${metric.collections_status_filter}`}
              subtitle={metric.count}
              suffix=""
            />
          ))}
      </Box>
    </Card>
  );
};
