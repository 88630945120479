import * as React from "react";
import { Show, SimpleShowLayout, DateField, NumberField } from "react-admin";

type Props = {};

const AffordabilityShow = (props: Props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NumberField source="total" />
        <NumberField source="housingCost" />
        <NumberField source="bills" />
        <NumberField source="creditCommittment" />
        <NumberField source="otherExpense" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

export default AffordabilityShow;
