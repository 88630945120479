import { makeStyles } from "@material-ui/core/styles";

export const styles = {
  startFormGroup: { display: "inline-block" },
  nextFormGroup: { display: "inline-block", paddingLeft: "5px" },
};

const useStyles = makeStyles(styles);

export default useStyles;
