import * as React from "react";
import {
  FC,
  Fragment,
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from "react";
import { Button as RAButton, useRefresh, useMutation } from "react-admin";
import { useTranslate } from "ra-core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import Button from "@material-ui/core/Button";
import { Select, TextField } from "@material-ui/core";

interface ConfirmProps {
  cancel?: string;
  confirm?: string;
  confirmColor?: string;
  isOpen?: boolean;
  loading?: boolean;
  onClose: MouseEventHandler;
  onConfirm?: MouseEventHandler;
  title: string;
  translateOptions?: object;
}

const ConfirmDialog: FC<ConfirmProps> = (props) => {
  const {
    isOpen,
    loading,
    title,
    children,
    confirm,
    cancel,
    onClose,
    onConfirm,
    translateOptions = {},
  } = props;
  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      if (onConfirm) {
        onConfirm(e);
      }
    },
    [onConfirm],
  );
  const translate = useTranslate();
  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);
  return (
    <Dialog
      open={isOpen ? isOpen : false}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {translate(title, { _: title, ...translateOptions })}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          <AlertError />
          {cancel}
        </Button>
        {!!onConfirm && (
          <Button disabled={loading} onClick={handleConfirm} autoFocus>
            <ActionCheck />
            {confirm}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  cancel: "Cancel",
  confirm: "Confirm",
  confirmColor: "primary",
  isOpen: false,
};

interface Option {
  id: string;
  name: string;
}

const ApproveButton = ({ application }: any) => {
  const [open, setOpen] = useState(false);
  const [maxAmount, setMaxAmount] = useState(0);
  const [maxDuration, setMaxDuration] = useState(0);
  const [rate, setRate] = useState(0);
  const [applicationState, setApplicationState] = useState("REFERRED");
  useEffect(() => setApplicationState(application?.status), [application]);
  useEffect(() => setMaxAmount(application?.max_amount), [application]);
  useEffect(() => setMaxDuration(application?.max_duration), [application]);
  useEffect(
    () => setRate(Math.round(application?.interestRate * 10000) / 100),
    [application],
  );
  const refresh = useRefresh();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const [mutate, { loading, error }] = useMutation();
  const approve = (event: any) => {
    mutate({
      type: "update",
      resource: "loans/adminui/approve",
      payload: {
        id: application.id,
        data: {
          max_amount: event.maxAmount,
          interestRate: event.rate / 100,
          max_duration: event.maxDuration,
          status:
            applicationState !== "Unknown"
              ? applicationState
              : application.status,
        },
      },
    });
    setOpen(false);
  };
  useEffect(() => {
    refresh();
  }, [refresh, loading]);
  if (error) {
    return (
      <ConfirmDialog
        isOpen={true}
        loading={false}
        title="Error Approving Loan"
        onClose={() => {
          mutate({
            type: "update",
            resource: "loans/adminui/approve",
            payload: {
              id: application.id,
              data: {},
            },
          });
        }}
      >
        Error {JSON.stringify(error)}
      </ConfirmDialog>
    );
  }
  const handleConfirm = async () => {
    approve({ maxAmount, maxDuration, rate, applicationState });
  };
  return (
    <Fragment>
      <RAButton label="Edit Application" onClick={handleClick} />
      <ConfirmDialog
        isOpen={open}
        loading={loading}
        title="Edit Application"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      >
        <Select
          native
          value={applicationState}
          onChange={(e: any) => setApplicationState(e.target.value)}
          inputProps={{
            name: "state",
          }}
        >
          <option>Unknown</option>
          <option value="REFERRED">REFERRED</option>
          <option value="APPROVED">APPROVED</option>
          <option value="DECLINED">DECLINED</option>
        </Select>
        <br></br>
        <TextField
          label="Max Amount (£)"
          value={maxAmount}
          onChange={(e: any) => setMaxAmount(e.target.value)}
        />
        <TextField
          label="Rate (%)"
          value={rate}
          onChange={(e: any) => setRate(e.target.value)}
        />
        <TextField
          label="Max Term (months)"
          value={maxDuration}
          onChange={(e: any) => setMaxDuration(e.target.value)}
        />
      </ConfirmDialog>
    </Fragment>
  );
};

export default ApproveButton;
