import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField } from "react-admin";

const bankaccountShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="provider.display_name" />
      <TextField source="bank_profile.user_notifiable_error" />
      <TextField source="status" />
      <DateField source="update_timestamp" />
      <TextField source="account_type" />
      <TextField source="display_name" />
      <TextField source="currency" />
      <TextField source="swift_bic" />
      <TextField source="number" />
      <TextField source="sort_code" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default bankaccountShow;
