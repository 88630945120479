import React from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import { QueryClient, QueryClientProvider } from "react-query";
import authProvider from "./authProvider";
import GoogleLoginPage from "./GoogleLoginPage";
import LayoutWithDetailDrawer from "./layout/LayoutWithDetailDrawer";

import UpdraftDataProvider from "./UpdraftDataProvider";
import customRoutes from "./customRoutes";

import { UnderwriterContextProvider } from "./UnderwriterContext";
import Dashboard from "./Dashboard";
import AccountTransactions from "./Transactions/AccountTransactions";
import AccountTransactionEdit from "./Transactions/AccountTransactionEdit";
import AccountTransactionShow from "./Transactions/AccountTransactionShow";
// import BankAccounts from "./Transactions/BankAccounts";
import BankAccountShow from "./Transactions/BankAccountShow";
import Profiles from "./Loans/Profiles";
import Affordability from "./Loans/Affordability";
import Applications from "./Loans/Applications";
import Users from "./Loans/Users";
import Decisions from "./Loans/Decisions";
import Salary from "./Loans/Salary";
import Housing from "./Loans/Housing";
import PaymentsDue from "./Vault/PaymentsDue";
import PaymentMI from "./Vault/PaymentMI";
import LoanStatus from "./Vault/LoanStatus";
import Mandates from "./Payments/Mandates";
import SalaryShow from "./Transactions/SalaryShow";
import OfferReviewProfileShow from "./Loans/OfferReviewProfileShow";

import "./App.css";

const httpClient = (url: string, options: any) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
let dataProvider: any = null;
let zoralConsoleBaseUrl: string | null = null;
switch (process.env.REACT_APP_BRANCH) {
  case "master":
    dataProvider = new UpdraftDataProvider(
      {
        transactions: "https://transaction-master.api.updraft.co.uk",
        loans: "https://loans-master.api.updraft.co.uk",
        vault: "https://vault-master.api.updraft.co.uk",
        payment: "https://payment-master.api.updraft.co.uk",
      },
      httpClient,
    );
    zoralConsoleBaseUrl = "https://console.de-dev.fairscore.ai/";
    break;
  // temporarily hosting a revised UI at https://underwriter-buffalo.co.uk that
  // uses the production backend for its data.
  case "feature/application_show_relayout":
  case "prod":
    dataProvider = new UpdraftDataProvider(
      {
        transactions: "https://transaction.api.updraft.co.uk",
        loans: "https://loans.api.updraft.co.uk",
        vault: "https://vault.api.updraft.co.uk",
        payment: "https://payment.api.updraft.co.uk",
      },
      httpClient,
    );
    zoralConsoleBaseUrl = "https://console.de-prod.fairscore.ai/";
    break;
  default:
    dataProvider = new UpdraftDataProvider(
      {
        transactions: "https://transaction-master.api.updraft.co.uk",
        loans: "https://loans-master.api.updraft.co.uk",
        vault: "https://vault-master.api.updraft.co.uk",
        payment: "https://payment-master.api.updraft.co.uk",
      },
      httpClient,
    );
    zoralConsoleBaseUrl = "https://console.de-dev.fairscore.ai/";
}

console.log("REACT_APP_BRANCH", process.env.REACT_APP_BRANCH);

const messages: any = {
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  onMissingKey: (key: string) => key,
});
const queryClient = new QueryClient();

export default function App() {
  // check we filled out our environment-specific values above correctly and
  // refine the nulls out of the types...
  if (zoralConsoleBaseUrl == null) {
    throw new Error("zoralConsoleBaseUrl not initialized correctly");
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UnderwriterContextProvider zoralConsoleBaseUrl={zoralConsoleBaseUrl}>
        <Admin
          disableTelemetry
          layout={LayoutWithDetailDrawer}
          customRoutes={customRoutes}
          loginPage={GoogleLoginPage}
          authProvider={authProvider}
          dashboard={Dashboard}
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
        >
          <Resource
            name="loans/adminui/profile"
            options={{ label: "Profile" }}
            {...Profiles}
          />
          <Resource name="loans/adminui/affordability" {...Affordability} />
          <Resource name="loans/authentication/users" {...Users} />
          <Resource name="loans/decision/salary" {...Salary} />
          <Resource name="loans/decision/housing" {...Housing} />
          <Resource name="loans/zoral/decisions" {...Decisions} />
          <Resource
            name="loans/adminui/application"
            options={{ label: "Applications" }}
            {...Applications}
          />
          <Resource name="loans/loans/affordabilityoverride" />
          <Resource name="loans/adminui/decision/data" />
          <Resource name="loans/adminui/credit_decision/cosmos" />
          <Resource name="loans/adminui/offer_review/salary" />
          <Resource
            name="loans/adminui/offer_review/profile"
            show={OfferReviewProfileShow}
          />
          <Resource name="loans/adminui/applications" />
          <Resource name="loans/adminui/approve" />
          <Resource
            name="transactions/adminui/bankaccounts"
            options={{ label: "Bank Accounts" }}
            // list={BankAccounts}
            show={BankAccountShow}
          />
          <Resource name="transactions/adminui/connectedbanks" />
          <Resource name="transactions/adminui/connectedcards" />
          <Resource name="transactions/adminui/bills" />
          <Resource name="transactions/adminui/overdraft" />
          <Resource name="transactions/decision/benefits" />
          <Resource name="transactions/underwriting/accounts/lenders/days/90" />
          <Resource name="transactions/underwriting/accounts/categories" />
          <Resource name="transactions/underwriting/accounts/names" />
          <Resource name="transactions/underwriting/accounts/insights/income" />
          <Resource
            name="transactions/adminui/accounttransactions"
            options={{ label: "Bank Transactions" }}
            list={AccountTransactions}
            edit={AccountTransactionEdit}
            show={AccountTransactionShow}
          />
          <Resource
            name="transactions/adminui/user/salary/cognito"
            show={SalaryShow}
          />
          <Resource name="transactions/category/categorisation" />
          <Resource name="transactions/category/categorisation/list" />
          <Resource name="transactions/category/categorisation/problem" />

          <Resource
            name="vault/adminui/loan/paymentsdue"
            options={{ label: "Payments Due" }}
            {...PaymentsDue}
          />
          <Resource
            name="vault/adminui/loan/paymentreport"
            options={{ label: "Payment MI" }}
            {...PaymentMI}
          />
          <Resource
            name="vault/adminui/loan/status"
            options={{ label: "Loan Status" }}
            {...LoanStatus}
          />

          <Resource
            name="payment/adminui/mandate"
            options={{ label: "Mandates" }}
            {...Mandates}
          />
        </Admin>
      </UnderwriterContextProvider>
    </QueryClientProvider>
  );
}
