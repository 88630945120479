import React, { useState } from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  TopToolbar,
  useDataProvider,
  useRefresh,
} from "react-admin";
import Button from "@material-ui/core/Button";
import SalaryReportDialog from "./SalaryReportDialog";

type Props = {};

const SalaryActions = ({ basePath, data, resource }: any) => {
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const runCategorisation = React.useCallback(async () => {
    if (data?.user_id) {
      await dataProvider.create(
        `transactions/category/categorisation/${data.user_id}`,
        { data: {} },
      );
      refresh();
    }
  }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <TopToolbar>
        <Button color="primary" onClick={() => setOpen(true)}>
          Report Problem
        </Button>
        <Button color="primary" onClick={() => runCategorisation()}>
          Run Categorize
        </Button>
      </TopToolbar>
      <SalaryReportDialog
        open={open}
        onConfirm={() => setOpen(false)}
        onClose={() => setOpen(false)}
        data={data}
      />
    </>
  );
};

const SalaryShow = (props: Props) => (
  <Show actions={<SalaryActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="user_id" />
      <NumberField source="count" />
      <ArrayField source="transactions">
        <Datagrid>
          <TextField source="id" />
          <DateField source="timestamp" />
          <NumberField source="amount" />
          <TextField source="description" />
          <ArrayField source="categoryscore_set">
            <Datagrid>
              <TextField source="category.name" label="Category" />
              <NumberField source="score" />
              <NumberField source="cluster" />
              <DateField source="created_at" showTime />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
      <ReferenceField
        source="user_id"
        reference="transactions/category/categorisation/list"
        label="Categorization Attempts"
      >
        <ArrayField source="data">
          <Datagrid>
            <DateField source="created_at" showTime />
            <DateField source="completed_at" showTime />
          </Datagrid>
        </ArrayField>
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default SalaryShow;
