import * as React from "react";
import {
  useGetOne,
  Identifier,
  UseDataProviderOptions,
  DataProviderContext,
} from "react-admin";
import { useQuery } from "react-query";
import UpdraftDataProvider from "./UpdraftDataProvider";

export function useLoanApplication(
  id: Identifier,
  options?: UseDataProviderOptions,
) {
  // we intentionally use the adminui route here so that it matches the resource
  // fetched by the main ApplicationShow component. Once that has been moved
  // over to the new resource (loans/loans/application) then this can also be
  // updated. Some components (such as AffordabilityOverrideArea) rely on this
  // being the same as the main ApplicationShow view so that, for example, they
  // can call the `refetch` function returned by this hook to update the main
  // application view.
  return useGetOne("loans/adminui/application", id, options);
}

type UseDecisionDataSourceOutputOptions = {
  enabled: boolean;
};

export function useDecisionDataSourceOutput(
  decisionId: number,
  dataSourceName: string,
  options?: UseDecisionDataSourceOutputOptions,
) {
  const dataProvider = React.useContext(
    DataProviderContext,
  ) as UpdraftDataProvider;

  const path = `zoral/decisions/${decisionId}/data_source_output/${dataSourceName}/`;
  const cacheKey = `loans:${path}`;

  const getDecisionDataSourceOutput = React.useCallback(async () => {
    const response = await dataProvider.endpointRequest("GET", "loans", path);
    if (response.status >= 200 && response.json < 300) {
      return Promise.reject(
        new Error(
          `Unexpected status code from API (${response.status}): ${response.body}`,
        ),
      );
    }

    return response.json.output;
  }, [dataProvider, path]);

  return useQuery(cacheKey, getDecisionDataSourceOutput, options);
}
