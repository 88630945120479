import * as React from "react";
import {
  Fragment,
  useEffect,
} from "react";
import { Button as RAButton, useRefresh, useMutation } from "react-admin";

interface Option {
  id: string;
  name: string;
}

const UpdateButton = ({ application }: any) => {
  const refresh = useRefresh();
  const [mutate, { loading }] = useMutation();
  const update = (event: any) => {
    mutate({
      type: "update",
      resource: "loans/adminui/update",
      payload: {
        id: application.id,
        data: {},
      },
    });
  };
  useEffect(() => {
    refresh();
  }, [refresh, loading]);
  const handleClick = async () => {
    update({});
  };
  return (
    <Fragment>
      {loading ? <RAButton label="Updating..." /> : <RAButton label="Update Decision" onClick={handleClick} />}
    </Fragment>
  );
};

export default UpdateButton;
