import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./styles";

const DetailDrawerContext = React.createContext({
  isOpen: false,
  setIsOpen: (value: boolean): void => {
    throw new Error("DetailDrawerContext not initialized properly");
  },
});

export function DetailDrawerManager({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <DetailDrawerContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </DetailDrawerContext.Provider>
  );
}

export function useIsDrawerOpen() {
  const { isOpen } = React.useContext(DetailDrawerContext);
  return isOpen;
}

export function useSetIsOpen() {
  const { setIsOpen } = React.useContext(DetailDrawerContext);
  return setIsOpen;
}

interface DetailDrawerProps {
  open?: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

/**
 * A material-ui `Drawer` designed to be used to reveal more details about a
 * field in the various 'show' UIs.
 */
export default function DetailDrawer({
  open = false,
  children,
  onClose,
}: DetailDrawerProps) {
  const classes = useStyles();
  const theme = useTheme();
  const anchor = theme.direction === "rtl" ? "left" : "right";
  const setIsOpen = useSetIsOpen();

  // somewhere around here is where I'm going to do an exclusive lock/automatic
  // dismissal of existing drawers...
  useEffect(() => {
    if (open) {
      setIsOpen(true);
      return () => {
        setIsOpen(false);
      };
    }
  }, [open, setIsOpen]);

  const contents = (
    <>
      <Toolbar className={classes.drawerHeader} variant="dense">
        <IconButton
          onClick={() => onClose()}
          color="inherit"
          aria-label="close details"
        >
          {theme.direction === "rtl" ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Toolbar>
      {children}
    </>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          open={open}
          onClose={onClose}
          variant="temporary"
          anchor={anchor}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {contents}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          open={open}
          variant="persistent"
          anchor={anchor}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {contents}
        </Drawer>
      </Hidden>
    </>
  );
}
