import * as React from "react";
import { useVersion, useDataProvider } from "react-admin";
import { Box, Card, CardHeader } from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/AccountBalance";
import EmojiPeople from "@material-ui/icons/EmojiPeople";
import CardWithIcon from "../Dashboard/CardWithIcon";
import Create from "./Create";
import Launch from "./Launch";
import Spacer from "./Spacer";

interface State {
  count: number | null;
  value: number | null;
  pending: number | null;
}

export default () => {
  const [state, setState] = React.useState<State>({
    count: null,
    value: null,
    pending: null,
  });
  const version = useVersion();
  const dataProvider = useDataProvider();
  const fetchStats = React.useCallback(async () => {
    const data = await dataProvider.getOne("loans/campaign/ntu", {
      id: "status",
    });
    const newState: State = {
      count: data.data.count,
      value: data.data.value,
      pending: data.data.pending,
    };
    setState((prevState: State) => {
      return { ...prevState, ...newState };
    });
  }, [dataProvider]);
  React.useEffect(() => {
    fetchStats();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Card>
      <CardHeader title="Campaign Management" />
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        <Create />
        <Spacer flexBasis={200} />
        <Launch />
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        <CardWithIcon
          to="/"
          icon={EmojiPeople}
          title="Count"
          subtitle={state.count !== null ? `${state.count}` : "-"}
          suffix=""
        />
        <CardWithIcon
          to="/"
          icon={MoneyIcon}
          title="Value"
          subtitle={state.value !== null ? `${state.value}` : "-"}
        />
        <CardWithIcon
          to="/"
          icon={EmojiPeople}
          title="Pending"
          subtitle={state.pending !== null ? `${state.pending}` : "-"}
        />
      </Box>
    </Card>
  );
};
