/**
 * Error codes as returned by Zoral.
 */
enum ApplicationDecisionResultCode {
  DebtCollection = 208,
  Deceased = 209,
  DebtManagementPlan = 401,
  GoneAway = 402,
  PaymentHoliday = 809,
  RecentDelinquency = 1009,
  CurrentDefault = 1011,
  SalaryNotConfirmed = 1021,
  BankSurnameMatch = 1102,
  GingerNotConnected = 1103,
  GingerNotMatched = 1104,
  IdentityNotChecked = 1201,
  Blocklist = 4005,
}

export default ApplicationDecisionResultCode;
