import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
} from "react-admin";
import PaginatedExportButton from "./PaginatedExportButton";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import {
  lastDayOfMonth,
  setDate,
  subMonths,
  addMonths,
  format,
} from "date-fns";

/*
interface PaymentMI {
  id: number;
  external_id: string | null;
  user_id: string | null;
  email: string | null;
  username_cache: string | null;
  regular_payment_day: number | null;
  regular_payment_amount: number | null;
  first_payment_date: string | null;
  duration: number | null;
  final_payment_date: string | null;
  final_payment_amount: number | null;
  expected_payment: number | null;
  actual_receipts: number | null;
  planned_receipts: number | null;
}
*/

const ActionButtons = (props: any) => (
  <>
    <PaginatedExportButton {...props} maxResults={25} />
  </>
);

const ListFilter = (props: any) => (
  <FilterList {...props}>
    <FilterListItem
      label="This Month"
      value={{
        date_range_after: format(
          setDate(lastDayOfMonth(new Date()), 1),
          "yyyy-MM-dd",
        ),
        date_range_before: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
      }}
    />
    <FilterListItem
      label="Last Month"
      value={{
        date_range_after: format(
          setDate(subMonths(lastDayOfMonth(new Date()), 1), 1),
          "yyyy-MM-dd",
        ),
        date_range_before: format(
          subMonths(lastDayOfMonth(new Date()), 1),
          "yyyy-MM-dd",
        ),
      }}
    />
    <FilterListItem
      label="Next Month"
      value={{
        date_range_after: format(
          setDate(lastDayOfMonth(addMonths(new Date(), 1)), 1),
          "yyyy-MM-dd",
        ),
        date_range_before: format(
          lastDayOfMonth(addMonths(new Date(), 1)),
          "yyyy-MM-dd",
        ),
      }}
    />
    <FilterListItem
      label="All Time"
      value={{
        date_range_after: undefined,
        date_range_before: undefined,
      }}
    />
  </FilterList>
);

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch source="search" />
      <ListFilter />
    </CardContent>
  </Card>
);

const PaymentsDueList = (props: any) => (
  <List {...props} actions={<ActionButtons />} aside={<FilterSidebar />}>
    <Datagrid rowClick="toggleSelection" isRowSelectable={(record) => false}>
      <TextField source="external_id" />
      <TextField source="username_cache" label="User" />
      <TextField source="email" />
      <NumberField source="expected_payment" sortable={false} />
      <NumberField source="planned_receipts" sortable={false} />
      <NumberField source="actual_receipts" sortable={false} />
      <NumberField source="unpaid_unplanned" sortable={false} />
    </Datagrid>
  </List>
);
export default PaymentsDueList;
