import * as React from "react";
import { useVersion, useDataProvider } from "react-admin";
import { Box, Button, Card, CardHeader } from "@material-ui/core";
import { Link } from "react-router-dom";
import MoneyIcon from "@material-ui/icons/AccountBalance";
import Group from "@material-ui/icons/Group";
import EmojiPeople from "@material-ui/icons/EmojiPeople";
import Today from "@material-ui/icons/Today";
import CardWithIcon from "./Dashboard/CardWithIcon";
import "../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LabelSeries,
} from "react-vis";
import { formatAbbreviated } from "./utils";

// const VerticalSpacer = () => <span style={{height: "100em"}} />;

interface WeekValue {
  start: number;
  end: number;
}

interface State {
  AllLoanAccepted?: number;
  StartAllLoanAccepted?: number;
  AllLoanBalance?: number;
  StartAllLoanBalance?: number;
  AllLoanCount?: number;
  StartAllLoanCount?: number;
  OpenLoanCount?: number;
  StartOpenLoanCount?: number;
  OpenRiskAdjustedRevenue?: number;
  RiskAdjustedRevenue?: number;
  ApprovedTotalLoanValue?: WeekValue;
  AutoApprovedLoanValue?: WeekValue;
  AutoApprovedAcceptedLoanValue?: WeekValue;
  AutoApprovedCappedLoanValue?: WeekValue;
  LowRiskApprovedLoanValue?: WeekValue;
  LowRiskDeclinedLoanValue?: WeekValue;
  quarter?: Array<{ x: string; y: number }>;
}

export default function Dashboard() {
  const [state, setState] = React.useState<State>({});
  const version = useVersion();
  const dataProvider = useDataProvider();
  const fetchStats = React.useCallback(async () => {
    const data = await dataProvider.getOne("loans/adminui/data/loans", {
      id: "week",
    });
    const newState: State = {};
    for (let i of data?.data?.data) {
      switch (i.Label) {
        case "AllLoanAccepted":
          newState.AllLoanAccepted = i.Values[0];
          newState.StartAllLoanAccepted = i.Values[i.Values.length - 1];
          break;
        case "AllLoanBalance":
          newState.AllLoanBalance = i.Values[0];
          newState.StartAllLoanBalance = i.Values[i.Values.length - 1];
          break;
        case "AllLoanCount":
          newState.AllLoanCount = i.Values[0];
          newState.StartAllLoanCount = i.Values[i.Values.length - 1];
          break;
        case "OpenLoanCount":
          newState.OpenLoanCount = i.Values[0];
          newState.StartOpenLoanCount = i.Values[i.Values.length - 1];
          break;
        case "AllLoanRiskAdjustedRevenue":
          newState.OpenRiskAdjustedRevenue = i.Values[i.Values.length - 1];
          newState.RiskAdjustedRevenue = i.Values[0];
          break;
        default:
          break;
      }
      const graphData = await dataProvider.getOne("loans/adminui/data/loans", {
        id: "quarter",
      });
      newState.quarter = graphData.data.data
        .map((x: any) => ({ x: x.end, y: x.approvals }))
        .reverse();
      setState((prevState: State) => {
        return { ...prevState, ...newState };
      });
      setState((prevState: State) => {
        return { ...prevState, ...newState };
      });
    }
  }, [dataProvider]);
  const fetchApprovalStats = React.useCallback(async () => {
    const data = await dataProvider.getOne("loans/adminui/data/approvals", {
      id: "week",
    });
    const newState: State = {};
    for (let i of data?.data?.data) {
      switch (i.Label) {
        case "ApprovedTotalLoanValue":
          newState.ApprovedTotalLoanValue = {
            start: i.Values[0],
            end: i.Values[i.Values.length - 1],
          };
          break;
        case "AutoApprovedLoanValue":
          newState.AutoApprovedLoanValue = {
            start: i.Values[0],
            end: i.Values[i.Values.length - 1],
          };
          break;
        case "AutoApprovedAcceptedLoanValue":
          newState.AutoApprovedAcceptedLoanValue = {
            start: i.Values[0],
            end: i.Values[i.Values.length - 1],
          };
          break;
        case "AutoApprovedCappedLoanValue":
          newState.AutoApprovedCappedLoanValue = {
            start: i.Values[0],
            end: i.Values[i.Values.length - 1],
          };
          break;
        case "LowRiskApprovedLoanValue":
          newState.LowRiskApprovedLoanValue = {
            start: i.Values[0],
            end: i.Values[i.Values.length - 1],
          };
          break;
        case "LowRiskDeclinedLoanValue":
          newState.LowRiskDeclinedLoanValue = {
            start: i.Values[0],
            end: i.Values[i.Values.length - 1],
          };
          break;
        default:
          break;
      }
      setState((prevState: State) => {
        return { ...prevState, ...newState };
      });
    }
  }, [dataProvider]);
  React.useEffect(() => {
    fetchStats();
    fetchApprovalStats();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Card>
      <CardHeader title="Updraft Underwriting Administration" />
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        <CardWithIcon
          to="/"
          icon={MoneyIcon}
          title="Lifetime RAR new this Week"
          subtitle={
            state.OpenRiskAdjustedRevenue && state.RiskAdjustedRevenue
              ? Math.round(
                  state.RiskAdjustedRevenue - state.OpenRiskAdjustedRevenue,
                )
              : "-"
          }
        />
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {formatAbbreviated(
          state.AllLoanAccepted != null && state.StartAllLoanAccepted != null
            ? state.AllLoanAccepted - state.StartAllLoanAccepted
            : null,
          (value, suffix) => (
            <CardWithIcon
              to="/"
              icon={Today}
              title="Week Loan Accepted"
              subtitle={`£ ${value}`}
              suffix={suffix}
            />
          ),
        )}
        <CardWithIcon
          to="/"
          icon={EmojiPeople}
          title="Week All Loan Count"
          subtitle={
            state.AllLoanCount && state.StartAllLoanCount
              ? state.AllLoanCount - state.StartAllLoanCount
              : "-"
          }
        />
      </Box>
      <Box display="flex" paddingBottom={1}>
        {formatAbbreviated(state.AllLoanBalance, (value, suffix) => (
          <CardWithIcon
            to="/"
            icon={MoneyIcon}
            title="All Loan Balance"
            subtitle={`£ ${value}`}
            suffix={suffix}
          />
        ))}
        <CardWithIcon
          to="/"
          icon={Group}
          title="Open Loan Count"
          subtitle={state.OpenLoanCount}
        />
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {formatAbbreviated(
          state.ApprovedTotalLoanValue != null
            ? state.ApprovedTotalLoanValue.start -
                state.ApprovedTotalLoanValue.end
            : null,
          (value, suffix) => (
            <CardWithIcon
              to="/"
              icon={Today}
              title="Week Approvals"
              subtitle={`£ ${value}`}
              suffix={suffix}
            />
          ),
        )}
        {formatAbbreviated(
          state.AutoApprovedLoanValue != null
            ? state.AutoApprovedLoanValue.start -
                state.AutoApprovedLoanValue.end
            : null,
          (value, suffix) => (
            <CardWithIcon
              to="/"
              icon={Today}
              title="Week AutoApprove"
              subtitle={`£ ${value}`}
              suffix={suffix}
            />
          ),
        )}
        {formatAbbreviated(
          state.AutoApprovedCappedLoanValue != null
            ? state.AutoApprovedCappedLoanValue.start -
                state.AutoApprovedCappedLoanValue.end
            : null,
          (value, suffix) => (
            <CardWithIcon
              to="/"
              icon={Today}
              title="Week Capped"
              subtitle={`£ ${value}`}
              suffix={suffix}
            />
          ),
        )}
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {formatAbbreviated(
          state.LowRiskApprovedLoanValue != null
            ? state.LowRiskApprovedLoanValue.start -
                state.LowRiskApprovedLoanValue.end
            : null,
          (value, suffix) => (
            <CardWithIcon
              to="/"
              icon={Today}
              title="Week LowRisk Approved"
              subtitle={`£ ${value}`}
              suffix={suffix}
            />
          ),
        )}
        {formatAbbreviated(
          state.LowRiskDeclinedLoanValue != null
            ? state.LowRiskDeclinedLoanValue.start -
                state.LowRiskDeclinedLoanValue.end
            : null,
          (value, suffix) => (
            <CardWithIcon
              to="/"
              icon={Today}
              title="Week LowRisk Declined"
              subtitle={`£ ${value}`}
              suffix={suffix}
            />
          ),
        )}
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {formatAbbreviated(
          state.AllLoanBalance != null && state.StartAllLoanBalance != null
            ? state.AllLoanBalance - state.StartAllLoanBalance
            : null,
          (value, suffix) => (
            <CardWithIcon
              to="/"
              icon={Today}
              title="Week Loan Balance"
              subtitle={`£ ${value}`}
              suffix={suffix}
            />
          ),
        )}
        <CardWithIcon
          to="/"
          icon={EmojiPeople}
          title="Week Open Loan Count"
          subtitle={
            state.OpenLoanCount && state.StartOpenLoanCount
              ? state.OpenLoanCount - state.StartOpenLoanCount
              : "-"
          }
        />
      </Box>
      <Box display="flex" flexWrap="wrap" paddingBottom={1}>
        {formatAbbreviated(state.AllLoanAccepted, (value, suffix) => (
          <CardWithIcon
            to="/"
            icon={MoneyIcon}
            title="All Loan Accepted"
            subtitle={`£ ${value}`}
            suffix={suffix}
          />
        ))}
        <CardWithIcon
          to="/"
          icon={Group}
          title="All Loan Count"
          subtitle={state.AllLoanCount}
        />
      </Box>
      <>
        <XYPlot height={200} width={1200} stackBy="y" xType="ordinal">
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis
            tickFormat={(v: string) => {
              return v.split("T")[0];
            }}
          />
          <YAxis tickFormat={(v: number) => `${+Number.parseFloat(v.toFixed(2)) / 1000}k`} />
          <VerticalBarSeries data={state.quarter} barWidth={0.9} />
          <LabelSeries
            data={state.quarter?.map((x: any) => ({
              ...x,
              label: formatAbbreviated(
                x.y,
                (value, suffix) => `£${+Number.parseFloat(value).toFixed(2)}${suffix}`,
              ),
            }))}
          />
        </XYPlot>
        <Box display="flex" flexWrap="wrap" paddingBottom={1}>
          <Button component={Link} to={{ pathname: "/campaign" }}>
            Campaign
          </Button>
          <Button component={Link} to={{ pathname: "/collections" }}>
            Collections
          </Button>
        </Box>
      </>
    </Card>
  );
}
