const AccountStatusMappings: { [type: string]: string } = {
  "..": "Unknown status",
  AA: "Early delinquency",
  AR: "Arrangement",
  BB: "Sustained delinquency",
  DA: "Debt assignment",
  DF: "Default",
  DM: "Debt management program",
  DS: "Deferred start",
  DT: "Dormant/inactive account",
  IC: "Insurance/Third Party Payments",
  IP: "Insurance claim pending",
  LS: "Low start repayment plan applies",
  MP: "Minimum payment made",
  OA: "Overpayment - in arrears",
  OK: "Okay",
  OP: "Overpayment",
  PH: "Payment holiday",
  PS: "Partial settlement",
  QY: "Query",
  RI: "Account re-instatement",
  RP: "Enforced repossession",
  SF: "Satisfied",
  ST: "Settled/closed",
  UC: "Unclassified",
  VA: "Voluntary arrangement",
  VR: "Voluntary repossession",
  VT: "Voluntary termination",
};

export default AccountStatusMappings;
