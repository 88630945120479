import * as React from "react";
import { FC, Fragment, useState, useCallback, MouseEventHandler } from "react";
import {
  Button as RAButton,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  useQueryWithStore,
} from "react-admin";
import { useTranslate } from "ra-core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ActionCheck from "@material-ui/icons/CheckCircle";
import AlertError from "@material-ui/icons/ErrorOutline";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

interface ConfirmProps {
  cancel?: string;
  confirm?: string;
  confirmColor?: string;
  isOpen?: boolean;
  loading?: boolean;
  onClose: MouseEventHandler;
  onConfirm: MouseEventHandler;
  title: string;
  translateOptions?: object;
}

const ConfirmDialog: FC<ConfirmProps> = (props) => {
  const {
    isOpen,
    loading,
    title,
    children,
    confirm,
    cancel,
    onClose,
    onConfirm,
    translateOptions = {},
  } = props;
  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      onConfirm(e);
    },
    [onConfirm],
  );
  const translate = useTranslate();
  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);
  return (
    <Dialog
      open={isOpen ? isOpen : false}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {translate(title, { _: title, ...translateOptions })}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          <AlertError />
          {cancel}
        </Button>
        <Button disabled={loading} onClick={handleConfirm} autoFocus>
          <ActionCheck />
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  cancel: "Cancel",
  confirm: "Confirm",
  confirmColor: "primary",
  isOpen: false,
};

interface Option {
  id: string;
  name: string;
}

const ResetViewsButton = ({ selectedIds }: { selectedIds: number[] }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [cat1, setCat1] = useState<string>("");
  const [cat2, setCat2] = useState<string>("");
  const [class1, setClass1] = useState<string>("");
  const [updateMany, { loading }] = useUpdateMany(
    "transactions/adminui/accounttransactions",
    selectedIds,
    { spend_cat_1: cat1, spend_cat_2: cat2, spend_class_1: class1 },
    {
      onSuccess: () => {
        refresh();
        notify("Transaction Updated");
        unselectAll("transactions/adminui/accounttransactions");
      },
      onFailure: () => notify("Error: transactions not updated", "warning"),
    },
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "transactions/adminui/account",
    // Hack to re-use the getOne API here
    payload: { id: "meta" },
  });
  return (
    <Fragment>
      <RAButton label="Update Categories" onClick={handleClick} />
      <ConfirmDialog
        isOpen={open}
        loading={loading}
        title="Update Categories"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      >
        <Autocomplete
          id="combo-box-cat-1"
          value={cat1}
          onChange={(_: any, cat1: string | null) => {
            if (cat1) {
              setCat1(cat1);
            }
          }}
          options={loaded && data ? data.cat_1 : []}
          getOptionLabel={(option: string) => (option ? option : "None")}
          style={{ width: 300 }}
          renderInput={(params: any) => (
            <TextField {...params} label="Cat 1" variant="outlined" />
          )}
        />
        <Autocomplete
          id="combo-box-cat-2"
          value={cat2}
          onChange={(_: any, cat2: string | null) => {
            if (cat2) {
              setCat2(cat2);
            }
          }}
          options={loaded && data ? data.cat_2 : []}
          getOptionLabel={(option: string) => (option ? option : "None")}
          style={{ width: 300, marginTop: 20 }}
          renderInput={(params: any) => (
            <TextField {...params} label="Cat 2" variant="outlined" />
          )}
        />
        <Autocomplete
          id="combo-box-class-1"
          value={class1}
          onChange={(_: any, class1: string | null) => {
            if (class1) {
              setClass1(class1);
            }
          }}
          options={loaded && data ? data.class_1 : []}
          getOptionLabel={(option: string) => (option ? option : "None")}
          style={{ width: 300, marginTop: 20 }}
          renderInput={(params: any) => (
            <TextField {...params} label="Class 1" variant="outlined" />
          )}
        />
      </ConfirmDialog>
    </Fragment>
  );
};

export default ResetViewsButton;
